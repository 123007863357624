/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

/**
 * 실소유인증 상태값 원래 Yn 이였는데 심사중임 추가됨
 */
export type IdentityVerificationYnEnum =
  (typeof IdentityVerificationYnEnum)[keyof typeof IdentityVerificationYnEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IdentityVerificationYnEnum = {
  NO: 'NO',
  YES: 'YES',
  UNDER_REVIEW: 'UNDER_REVIEW',
} as const;
