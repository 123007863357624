import { useState } from 'react';

export interface IPartner {
  type: 'hana' | 'nh' | 'nhAllOne' | 'samsung' | 'DL' | 'newNH';
}

export default function usePartner(initPartner: IPartner) {
  const [partner] = useState(initPartner);

  return {
    get hasType() {
      return ['hana', 'nh', 'newNH', 'nhAllOne', 'samsung', 'DL'].includes(partner.type);
    },
    getType() {
      return partner.type;
    },
  };
}
