import getQueryString from './getQueryString';

export default function getPath(route, query) {
  if (!route) {
    return null;
  }

  if (!route.path) {
    return route.key;
  }

  const copiedQuery = { ...query };

  const matched = route.path.match(/:[a-zA-Z_-]+/) || [];
  const replaced = matched.reduce((acc, item) => {
    const key = item.slice(1);
    const value = copiedQuery[key];
    if (!value) {
      throw new Error(`Required parameter is not specified: ${route.key}`);
    }
    delete copiedQuery[key];
    return acc.replace(item, value);
  }, route.path);

  return `${replaced}${getQueryString(copiedQuery)}`;
}
