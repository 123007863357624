export default class PopupPermissionDeniedError extends Error {
  constructor() {
    super();

    /** @type {'PopupPermissionDenied'} */
    this.name = 'PopupPermissionDenied';

    this.message = '팝업이 차단되었습니다';
  }
}
