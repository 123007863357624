/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type AesType = (typeof AesType)[keyof typeof AesType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AesType = {
  Default: 'Default',
  GoodKing: 'GoodKing',
  NH: 'NH',
} as const;
