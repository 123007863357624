import { useEffect } from 'react';

import dynamic from 'next/dynamic';

import { setTag } from '@sentry/nextjs';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { parseCookie, setCookie } from 'coreSrc/core/js/utils/cookie';
import delay from 'coreSrc/core/js/utils/delay/delay';
import jwt from 'coreSrc/core/js/utils/jwt/jwt';

import { RICHGO_API_REGISTER_TOKEN_KEY } from 'coreSrc/base/userSystemManager/user/authManager/const';

const SignUpScreen = dynamic(() => import('hostSrc/comp/SignUpScreen'), { ssr: false });

interface IPartnerProps {}

const Partner: React.FunctionComponent<IPartnerProps> = () => {
  const system = useSystem();

  useEffect(() => {
    init();

    async function init() {
      const partnerType = system.partner.getType();

      addSentryContext();
      logAnalytics();

      await delay(1000);

      handleSamsung();

      function addSentryContext() {
        setTag('partner_type', partnerType || 'none');
      }

      function logAnalytics() {
        richgoAnalytics.log('partner', { type: partnerType });
      }

      function handleSamsung() {
        const isSamsung = partnerType === 'samsung';
        if (isSamsung) {
          const role = jwt.decode(system.router.query.token).payload.role;
          const hasRegisterToken = role === 'RegisterToken';
          if (hasRegisterToken) {
            const registerToken = parseCookie(document.cookie)[RICHGO_API_REGISTER_TOKEN_KEY];
            setCookie(`${RICHGO_API_REGISTER_TOKEN_KEY}=null; max-age=0; path=/`);
            system.dialog.open(
              <SignUpScreen
                registerToken={registerToken}
                onClose={() => system.router.go('myHouses')}
              />
            );
          }
        }
      }
    }
  }, []);

  return null;
};

export default Partner;
