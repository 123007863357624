/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type TradeType = (typeof TradeType)[keyof typeof TradeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TradeType = {
  Meme: 'Meme',
  Jeonse: 'Jeonse',
  Rent: 'Rent',
  ShortTermRental: 'ShortTermRental',
} as const;
