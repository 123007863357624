import styled from 'styled-components';

interface IDarkProps {
  className?: string;
  onClick?: () => void;
}

let Dark: React.FunctionComponent<IDarkProps> = (props) => {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
let Blue: React.FunctionComponent<IDarkProps> = (props) => {
  return (
    <div
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
Dark = styled(Dark)`
  width: 100%;

  padding: 14px;

  color: #fff;
  background: rgba(42, 47, 55, 0.7);

  border-radius: 8px;

  white-space: pre-wrap;
  text-align: center;
  font-size: 16px;
`;
Blue = styled(Blue)`
  width: 100%;

  padding: 12px 14px;

  color: #fff;
  background: #3182f6;

  border-radius: 8px;
  font-weight: 500;

  white-space: pre-wrap;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const Toast = {
  Dark,
  Blue,
};

export default Toast;
