import { setCookie } from 'coreSrc/core/js/utils/cookie';
import { AppContext } from 'next/app';

export default class ContextCookieHelper {
  static getCookie(appContext?: AppContext) {
    const contextCookieString = appContext?.ctx.req?.headers?.cookie ?? '';
    if (appContext?.ctx.req) return contextCookieString;
    return document.cookie;
  }

  static push(appContext: AppContext, entries: string[]) {
    if (!appContext['COOKIE']) {
      const COOKIE = { entries };
      return void Object.assign(appContext, { COOKIE });
    }

    appContext['COOKIE'].entries.push(...entries);
  }

  static flush(appContext: AppContext) {
    const entries = appContext['COOKIE']?.entries ?? [];
    if (entries.length === 0) return;

    const isSsr = appContext.ctx.res ? true : false;
    if (isSsr) {
      // if (!appContext.ctx.res.writableEnded) {
      appContext.ctx.res.setHeader('Set-Cookie', entries);
      // }
    } else {
      entries.forEach((entry) => {
        setCookie(entry);
      });
    }
  }
}
