import LoadingOverlay from '../../comp/LoadingOverlay';

interface IWebAppLoadingProps {
  className?: string;
  hidden: boolean;
}

function WebAppLoading(props: IWebAppLoadingProps) {
  if (props.hidden) return null;
  return <LoadingOverlay overlayColor="#000" />;
}

export default WebAppLoading;
