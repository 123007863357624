import { useContext } from 'react';

import PageStoreContext from './PageStoreContext';

export function usePageStore(name) {
  const { state, actions } = useContext(PageStoreContext);
  if (state[name] === undefined) {
    throw new Error(`PageStore: "${name}" is not exist.`);
  }
  return { state: state[name], actions: actions[name] };
}
export default usePageStore;
