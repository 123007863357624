/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

/**
 * 매물 상태
 */
export type SellGoodsStageType = (typeof SellGoodsStageType)[keyof typeof SellGoodsStageType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SellGoodsStageType = {
  WRITE_COMPLETED: 'WRITE_COMPLETED',
  SALE: 'SALE',
  COMPLETE: 'COMPLETE',
  DELETE_ON_SALE: 'DELETE_ON_SALE',
  DELETE_BEFORE_SALE: 'DELETE_BEFORE_SALE',
  DENIAL: 'DENIAL',
} as const;
