import {useEffect} from 'react';
import Spacer from '../../../coreSrc/core/js/components/Layout/Spacer';
import {useAdBanner} from '../AdBannerWithApi';

function useAdsOnAndroidExit(system) {
  const { AdBannerWithApi, Modal, Button, fetchAdBanner } = useAdBanner({ type: 'modal' });

  const handler = async (e) => {
    if (e.data === 'openAndroidExitPopup') {
      const isMobile = system.detector.isMobile;
      const isIOS = system.detector.isIOS;
      const deviceType = isMobile ? (isIOS ? 2 : 1) : 3;

      const { isError, data } = await fetchAdBanner('exitPopup', deviceType);

      system.dialog.open(
        <Modal>
          {!isError && <AdBannerWithApi location="exitPopup" adData={data} />}

          <Spacer justify="space-around">
            <Button onClick={() => system.router.back()}>취소</Button>
            <Button
              onClick={() => {
                globalThis.webview.postMessage(
                  JSON.stringify({
                    type: 'exitApp',
                  })
                );
                system.router.back();
              }}>
              종료
            </Button>
          </Spacer>
        </Modal>
      );
    }
  };

  useEffect(() => {
    if (!globalThis.webview) return;

    globalThis.webview.addMessageListener(handler);

    return () => {
      globalThis.webview.removeMessageListener(handler);
    };
  }, []);

  return;
}

export default useAdsOnAndroidExit;
