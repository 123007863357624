import { useEffect, useState } from 'react';
import styled from 'styled-components';

import useSystem from 'coreSrc/base/systemManager/useSystem';
import routings from '../../../../../hostSrc/_initDatas/routing';
import PopupContainer from './PopupContainer';

const SD_Layer = styled.div`
  position: static;
`;

function reduce(dataHash, type) {
  return Object.entries(dataHash).reduce(
    (acc, [key, item]) => ({
      ...acc,
      [key]: { key, ...item, type },
    }),
    {}
  );
}

const popupData = {
  ...reduce(routings.popups, 'popup'),
  ...reduce(routings.dialogs, 'dialog'),
};

function PopupLayer() {
  const sys = useSystem();
  const [hashes, setHashes] = useState([]);
  useEffect(() => {
    sys.router.setOnPopupChangeListener(setHashes);
  }, []);

  const [activeComps, setActiveComps] = useState(null);
  useEffect(() => {
    const popups = [...hashes];
    const newActiveComps = (activeComps || [])
      .map(({ id, key, ...comp }) => {
        const popupIndex = popups.findIndex((el) => el?.id === id);
        if (popupIndex === -1) {
          return null;
        }
        popups[popupIndex] = null;
        return { id, key, ...comp };
      })
      .filter((it) => it !== null)
      .concat(
        popups
          .filter((item) => item !== null && item !== undefined)
          .map((item) => ({
            ...item,
            ...popupData[item.key],
          }))
      );

    setActiveComps(newActiveComps);
  }, [hashes]);

  useEffect(() => {
    document.body.classList.toggle('noscroll', (activeComps?.length || 0) > 0);
  }, [activeComps]);

  const onClickClose = () => {
    sys.router.back();
  };

  return (
    // NOTE: kangseongofdk
    // - 스타일을 주입하는 방식은 바뀌어야 한다.
    //  아래처럼 특정 타입일 때 분기를 치는 방식은 좋지 않다.
    //  차라리 routing.js 에서 type 같은 방식으로 관리되는 게 낫다.
    // - 물론 사용자가 커스텀하게 수정할 수 있는 방안도 있어야 한다.
    <SD_Layer id="popup-layer">
      {activeComps?.map(({ id, type, Comp, data, containerOptions, ...attrs }) => (
        <PopupContainer
          center={type === 'dialog'}
          noContainer={type === 'dialog'}
          onClickOverlay={onClickClose}
          {...(attrs || {})}
          {...(containerOptions || {})}
          key={id}
        >
          <Comp {...(data || {})} onClickClose={onClickClose} />
        </PopupContainer>
      ))}
    </SD_Layer>
  );
}

export default PopupLayer;
