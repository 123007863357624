class PopupStack {
  constructor() {
    this.stack = [null];
    this.index = 0;
  }

  findById(id) {
    return this.stack.find((it) => it?.id === id);
  }

  get size() {
    return this.stack.length;
  }

  get top() {
    return this.stack[this.stack.length - 1];
  }

  push(item) {
    if (item === null && this.top === null) {
      return;
    }

    // if (this.top?.key === 'dialog') {
    //   this.replaceItem(item);
    // } else {
    this.pushItem(item);
    // }
  }

  pushItem(item) {
    this.stack.push(item);
    this.index = this.stack.length - 1;
  }

  replaceItem(item) {
    this.stack[this.stack.length - 1] = item;
  }

  pop() {
    return this.stack.pop();
  }
}

export default PopupStack;
