import type { AppContext } from 'next/app';

import { RICHGO_API_HOST } from 'coreSrc/core/js/env';

export default class ContextRedirectHelper {
  static async toDanjiPath(appContext: AppContext) {
    try {
      const kbstarId = appContext.router.query.KBM;
      const kbstarAreaIndex = appContext.router.query.areaType;
      const response = await fetch(
        `${RICHGO_API_HOST}/data/kb-detail/${kbstarId}/${kbstarAreaIndex}`
      );
      if (response.status !== 200) throw response;
      const json = await response.json();
      return `/realty/danji/${json.result.richgoId}?future=true&pyeongType=${json.result.supplyAreaPyeong}`;
    } catch (error) {
      return '/404';
    }
  }

  static getSearchParams(url: string) {
    return url.split('?')[1] ?? '';
  }

  static toHome() {
    return '/realty/richgoMap';
  }
}
