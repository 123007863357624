const DETAIL_PAGE_HEADER = 10;

const ZIDX = {
  DETAIL_PAGE_HEADER,
  DETAIL_PAGE_TABS: DETAIL_PAGE_HEADER - 1,
  MY_HOUSE_HEADER: 10,
  TOOLTIP: 5,
  POPUP_CONTAINER: 300,
};

export default ZIDX;
