/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type HomeScanType = (typeof HomeScanType)[keyof typeof HomeScanType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HomeScanType = {
  danji: 'danji',
  officetel: 'officetel',
  villa: 'villa',
} as const;
