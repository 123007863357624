import DefaultSkin from './DefaultSkin';

type ConfirmSkinProps = {
  title: string;
  message: string;
  actions: {
    onClick?: () => void;
    label?: string;
  }[];
  onClickClose?: () => void;
};
function ConfirmSkin({
  title,
  message,
  actions: _actions,
  onClickClose,
  ...props
}: ConfirmSkinProps) {
  const actions = [
    {
      ...(_actions?.[0] || {}),
      label: _actions?.[0]?.label || '확인',
    },
    {
      ...(_actions?.[1] || {}),
      label: _actions?.[1]?.label || '취소',
    },
  ];

  return (
    <DefaultSkin
      title={title}
      message={message}
      actions={actions}
      onClickClose={onClickClose}
      {...props}
    />
  );
}

export default ConfirmSkin;
