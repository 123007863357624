import { useEffect } from 'react';
import { Bounce, Flip, Slide, Zoom, toast } from 'react-toastify';

import ToastSampleSkin from './skin/ToastSampleSkin';

const DEFAULT_DURATION = 3000;
/** @type {import("react-toastify").ToastPosition} */
const DEFAULT_POSITION = toast.POSITION.TOP_CENTER;
const TRANSITION = {
  SLIDE: Slide,
  BOUNCE: Bounce,
  ZOOM: Zoom,
  flip: Flip,
};
const BaseSkin = ToastSampleSkin;

/**
 * @param {React.ReactNode} content
 * @param {{
 *   id?: string,
 *   duration?: number | false,
 *   closeOnClick?: boolean,
 *   style?: React.CSSProperties,
 *   pauseOnFocusLoss?: boolean,
 * }} [config]
 */
function open(content, config = {}) {
  if (typeof content === 'string') {
    content = <BaseSkin content={content} />;
  }

  config.duration ??= DEFAULT_DURATION;
  config.transition ??= TRANSITION.SLIDE;
  config.closeOnClick ??= true;
  config.pauseOnFocusLoss ??= false;
  config.style ??= {};

  config.position ??= DEFAULT_POSITION;

  toast(content, {
    position: config.position,
    toastId: config.id,
    autoClose: config.duration,
    transition: config.transition,
    closeOnClick: config.closeOnClick,
    style: config.style,
    pauseOnFocusLoss: config.pauseOnFocusLoss,
  });
}

/** @param {string} id */
function close(id) {
  toast.dismiss(id);
}

function closeAll() {
  toast.dismiss();
}

function extendOpenPosition(position) {
  /** @type {typeof open} */
  return function (content, config = {}) {
    open(content, { ...config, position });
  };
}

const TOAST_MANAGER = {
  open,
  openTopLeft: extendOpenPosition(toast.POSITION.TOP_LEFT),
  openTopCenter: extendOpenPosition(toast.POSITION.TOP_CENTER),
  openTopRight: extendOpenPosition(toast.POSITION.TOP_RIGHT),
  openBottomLeft: extendOpenPosition(toast.POSITION.BOTTOM_LEFT),
  openBottomCenter: extendOpenPosition(toast.POSITION.BOTTOM_CENTER),
  openBottomRight: extendOpenPosition(toast.POSITION.BOTTOM_RIGHT),
  close,
  closeAll,
  TRANSITION,
};

/**
 * <h1>호스트 접근 허용!!!!</h1>
 *
 * 토스트 매니저
 * @examples
 * const sys = useSystem();
 * const toast = sys.toast;
 * // 기본 텍스트 토스트
 * toast.openXXX('컨텐츠')
 * // 스킨 토스트
 * toast.openXXX(Component)
 * // 옵션 추가 토스트
 * toast.openXXX('컨텐츠',{})
 * // id기반 토스트 닫기
 * toast.close(id)
 * // id기반 토스트 모두 닫기
 * toast.closeAll()
 */
function useToastReducer() {
  useEffect(() => {
    toast.configure({
      autoClose: DEFAULT_DURATION,
      position: DEFAULT_POSITION,
      hideProgressBar: true,
      closeButton: false,
    });
  });

  return TOAST_MANAGER;
}

export default useToastReducer;
