/**
 * @prettier
 */
import useUserSystem from 'coreSrc/base/userSystemManager/user/useUserSystem';

export interface IQueryType {
  common: null;
  financeMenu: null;
  danji: Indexable & {
    id: string;
  };
  region: Indexable & {
    bjdInfo: string;
    bjdCode: string;
  };
  road: Indexable & {
    id: string;
  };
  highSchool: Indexable & {
    schoolId: string;
  };
  subwayStation: Indexable & {
    subwayId: string;
    id: string;
  };
  subwayLine: Indexable & {
    lineInfo: string;
    subwayId: string;
  };
  redevelop: Indexable & {
    id: string;
  };
  rebuilding: Indexable & {
    id: string;
  };
  remodeling: Indexable & {
    id: string;
  };
  chat: null;
}

interface Indexable {
  [key: string]: any;
}

const useGetCTQueryString = <K extends keyof IQueryType>(key: K, data: IQueryType[K]) => {
  const user = useUserSystem();

  // 채널톡으로 보낼 정보들 이 객체에 추가
  let query = {};
  query = {
    name: user.profile.getName() ?? '',
    email: user.profile.getEmail() ?? '',
    user_id: user.profile.getId() ?? '',
    statusKey: key ?? '',
  };

  switch (key) {
    case 'common':
      query = { ...query };
      break;
    case 'financeMenu':
      query = { ...query };
      break;
    case 'danji':
      query = { ...query, danjiId: data?.id ?? '' };
      break;
    case 'region':
      query = { ...query, bjdInfo: data?.bjdInfo ?? '', bjdCode: data?.bjdCode ?? '' };
      break;
    case 'road':
      query = { ...query, roadId: data?.id ?? '' };
      break;
    case 'highSchool':
      query = { ...query, schoolId: data?.schoolId ?? '' };
      break;
    case 'subwayStation':
      query = { ...query, id: data?.id ?? '', subwayId: data?.subwayId ?? '' };
      break;
    case 'subwayLine':
      query = { ...query, lineInfo: data?.lineInfo ?? '', subwayId: data?.subwayId ?? '' };
      break;
    case 'chat':
      query = { ...query };
      break;
    default:
      break;
  }

  const queryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join('&');

  return `https://richgo.channel.io/lounge?${queryString}`;
};

export default useGetCTQueryString;
