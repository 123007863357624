/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type RegisterUserType = (typeof RegisterUserType)[keyof typeof RegisterUserType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegisterUserType = {
  OWNER: 'OWNER',
  RENTER: 'RENTER',
  FAMILY: 'FAMILY',
  BROKER: 'BROKER',
} as const;
