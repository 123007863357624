type GetQueryStringOptions = {
  keepOriginal?: boolean;
};

export default function getQueryString(
  params: Record<string, any>,
  { keepOriginal }: GetQueryStringOptions = {}
) {
  const searchParams = new URLSearchParams(keepOriginal ? location.search : '');

  if (Object.keys(params).length !== 0) {
    Object.entries(params).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.set(key, value);
      }
    });
  }

  const result = searchParams.toString();
  return result ? `?${result}` : '';
}
