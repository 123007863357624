/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

/**
 * 전화번호인증에서  사용할 것이지 타입
 */
export type UserAuthTelType = (typeof UserAuthTelType)[keyof typeof UserAuthTelType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserAuthTelType = {
  RADAR: 'RADAR',
  HOMESCAN: 'HOMESCAN',
} as const;
