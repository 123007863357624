/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

/**
 * 매물 권한
 */
export type SellGoodsAuthorityType =
  (typeof SellGoodsAuthorityType)[keyof typeof SellGoodsAuthorityType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SellGoodsAuthorityType = {
  NORMAL_USER: 'NORMAL_USER',
  MATCHING_BROKER: 'MATCHING_BROKER',
  GOODS_OWNER: 'GOODS_OWNER',
} as const;
