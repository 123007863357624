import type { AppContext } from 'next/app';

import {
  RICHGO_API_ACCESS_TOKEN_KEY,
  RICHGO_API_REFRESH_TOKEN_KEY,
} from 'coreSrc/base/userSystemManager/user/authManager/const';

import { RICHGO_API_HOST } from '../../env';
import { setCookie } from '../cookie';

interface IUseResponseMiddlewarePayload {
  response: Response;
  appContext?: AppContext;
}

export default async function useResponseMiddleware(payload: IUseResponseMiddlewarePayload) {
  if (payload.response.url.includes(RICHGO_API_HOST)) {
    if (isWithdrawCheckApi(payload.response.url)) {
      if (payload.response.status === 400) {
        setCookie(`${RICHGO_API_REFRESH_TOKEN_KEY}=null; path=/; max-age=0;`);
        setCookie(`${RICHGO_API_ACCESS_TOKEN_KEY}=null; path=/; max-age=0;`);
      }
    }
  }

  return payload.response;
}

function isWithdrawCheckApi(url: string) {
  if (url.includes('/user/V2/access-token')) return true;
  if (url.includes('/user/V2/profile')) return true;
  return false;
}
