import type { IPartner } from './partner/usePartner';
import usePartner from './partner/usePartner';

import useToastReducer from './toastManager/useToastReducer';
import { useDialogReducer } from './dialogManager';
import { useRouteReducer } from './routeManager';
import useShare from './shareManager/useShare';
import { useDetector } from './detector';
import useContextWindowRecuder from '../../base/window/useContextWindowReducer';

export default function useSystemReducer(userAgent: string, initPartner?: IPartner) {
  const detector = useDetector(userAgent);
  const router = useRouteReducer();
  const dialog = useDialogReducer(router);
  const toast = useToastReducer();
  const partner = usePartner(initPartner);
  const share = useShare(toast);
  const windows = useContextWindowRecuder();

  return Object.freeze({
    detector,
    partner,
    router,
    dialog,
    toast,
    share,
    windows,
  });
}
