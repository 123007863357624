/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type CommunityStatusCode = (typeof CommunityStatusCode)[keyof typeof CommunityStatusCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CommunityStatusCode = {
  NO: 'NO',
  USER_DELETE: 'USER_DELETE',
  ADMIN_DELETE: 'ADMIN_DELETE',
} as const;
