export enum ScreenViewTypeEnum {
  sellingAddItem = '/selling/selling-my-item',
  radarAdd = '/radar/add-radar',
  purchaseBrokerPass = '/purchase/broker-pass',
  sellingFindUser = '/broker/radar/user-search',
  sellingContactGuide = '/broker/radar/contact-guide',
  danjiDetail = '/detail/danji',
  brokerDetail = '/detail/broker',
  checklist = '/realty/checklist',
  ranking = '/realty/analytics/ranking',
  expert = '/realty/analytics/expert',
  brokerPurchaseLanding = 'broker-purchase-landing',
  map = '/map',
  home = '/home',
  minOfferHistory = 'MinOfferPriceAnalyticsBottomSheet',
  realTradeHistory = 'DanjiRealTradeHistoryScreen',
  notificationCenter = 'RealtyNotificationCenterScreen',
  todayRealty = 'TodayRealty',
  auctionDetail = 'auctionDetail', // 경매 상세 화면
  auctionIntroduction = 'auctionIntroduction', // 경매소개화면
  auctionPayment = 'auctionPayment', // 경매결제화면
  paymentComplete = 'paymentComplete', // 결제완료
  homeFeed = 'homeFeed', // 홈피드
}

export const ButtonClickMap = {
  sellingAddItemAuth: 'seller-add-sale-item-after-auth',
  sellingAddItemDanjiSearchEnter: 'selling-add-item-danji-search-enter',
  sellingAddItemDanjiSearchComplete: 'selling-add-item-danji-search-complete',
  sellingMyItem: 'seller-sell-my-item',
  radarAdd: 'buyer-add-radar',
  brokerChat: 'broker-open-chat-with-seller',
  brokerPropose: 'broker-propose-mediation-to-seller',
  brokerContactToNewUser: 'broker-contact-to-new-user',
  brokerContactToMyUser: 'broker-contact-to-my-user',
  brokerOpenUserContact: 'broker-open-user-contact-information',
  danjiDetailSellingBanner: 'seller-enter-selling-from-danji',
  brokerDetailSellingBanner: 'seller-enter-selling-from-broker',
  mapBrokerProfile: 'map-broker-profile-pin',
  callBrokerBottom: 'call-broker-bottom',
  callBrokerNextToProfile: 'call-broker-next-to-profile',
  checkList: 'checklist',
};

export type ButtonClickType = keyof typeof ButtonClickMap;
