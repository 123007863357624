import styled from 'styled-components';

function ToastSampleSkin({ content }) {
  return <SD_Text>{content}</SD_Text>;
}

const SD_Text = styled.div`
  padding: 12px;
  border-radius: 12px;
`;

export default ToastSampleSkin;
