const isPc = (sys) => {
  // console.log('sys.router', sys.router);
  if (sys.router.asPath.indexOf('/mapTest') > -1) {
    return !sys.detector.isMobile;
  }
  return (
    sys.router.asPath.split('#')[0] === '/pc' ||
    sys.router.asPath.split('#')[0].indexOf('/pc') > -1 ||
    sys.router.asPath.split('#')[0] === '/pc2'
  );
};
export default isPc;
