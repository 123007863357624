import styled from 'styled-components';

interface IGridProps {
  style?: any;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
  flow?: 'row' | 'column';
  templateColumns?: string;
  autoColumns?: string;
  templateRows?: string;
  autoRows?: string;
  placeContent?: string;
  placeItems?: string;
  gap?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
  background?: string;
  onClick?: (e: React.MouseEvent) => void;
}

let Grid: React.FunctionComponent<IGridProps> = (props) => {
  function onClick(e: React.MouseEvent) {
    props.onClick?.(e);
  }

  return (
    <div className={props.className} onClick={onClick}>
      {props.children}
    </div>
  );
};

Grid = styled(Grid)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
  display: grid;

  grid-auto-flow: ${({ flow }) => flow || 'row'};
  grid-template-columns: ${({ templateColumns }) => templateColumns || 'auto'};
  grid-template-rows: ${({ templateRows }) => templateRows || 'auto'};

  grid-template-columns: ${({ autoColumns }) => (autoColumns ? 'none' : '')};
  grid-template-rows: ${({ autoRows }) => (autoRows ? 'none' : '')};
  grid-auto-columns: ${({ autoColumns }) => autoColumns};
  grid-auto-rows: ${({ autoRows }) => autoRows};

  place-content: ${({ placeContent }) => placeContent || 'unset'};
  place-items: ${({ placeItems }) => placeItems || 'unset'};

  gap: ${({ gap }) => gap || 'normal'};

  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};

  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};

  background: ${({ background: backgroundColor }) => backgroundColor || 'transparent'};
`;

export default Grid;
