/**
 * @prettier
 */
import React from 'react';

import styled from 'styled-components';

interface ICustomizeOptions {
  width?: string;
  height?: string;
  disabled?: boolean;
  cursor?: 'inherit' | 'pointer';
  margin?: string;
  padding?: string;
  color?: string;
  duration?: number;
}

interface Props extends ICustomizeOptions {
  name: string;
  type?: 'svg' | 'png';
  onClick?: ((event?: React.MouseEvent<HTMLDivElement | HTMLImageElement>) => void) | null;
  className?: string;
}

const Icon = ({
  name,
  width,
  height,
  onClick = null,
  disabled = false,
  margin = '0px',
  padding = '0px',
  cursor = undefined,
  type = 'svg',
  className,
  color,
  duration,
}: Props) => {
  if (type === 'svg' && height === undefined) {
    // png는 기본값 안주기 위해 따로 조건걸었음
    height = '26px';
  }
  if (type === 'svg' && width === undefined) {
    // png는 기본값 안주기 위해 따로 조건걸었음
    width = '26px';
  }
  let isSSR = true;
  try {
    const t0: any = window;
    isSSR = false;
  } catch (e) {
    isSSR = true;
  }
  if (isSSR) return null;
  if (color) {
    return (
      <SD_ColorIcon
        margin={margin}
        padding={padding}
        onClick={disabled !== true ? onClick : null}
        cursor={cursor ? cursor : onClick !== null && !disabled ? 'pointer' : 'inherit'}
        disabled={disabled}
        width={width}
        height={height}
        color={color}
        duration={duration}
        data-src={`/icons/svgFiles/${name}.svg`}
        className={className}
      />
    );
  }

  return (
    <SD_IconImg
      margin={margin}
      padding={padding}
      onClick={disabled !== true ? onClick : null}
      cursor={cursor ? cursor : onClick !== null && !disabled ? 'pointer' : 'inherit'}
      disabled={disabled}
      width={width}
      height={height}
      src={type === 'svg' ? `/icons/svgFiles/${name}.${type}` : `/icons/images/${name}.${type}`}
      // alt={`${name}-icon`}
      loading="lazy"
      color={color}
      className={className}
      // onError={(e) => handleOnError(e)}
    />
  );
  // return (
  //     <>
  //         <img
  //             style={{
  //                 width: width,
  //                 minWidth: width,
  //                 maxWidth: width,
  //                 height: height,
  //                 minHeight: height,
  //                 maxHeight: height,
  //                 cursor: cursor,
  //                 margin: margin,
  //                 padding: padding,
  //                 opacity: disabled ? 0.5 : 1,
  //             }}
  //             color={color}
  //             // disabled={disabled}
  //             onClick={disabled !== true ? onClick : null}
  //             src={type === 'svg' ? `/icons/svgFiles/${name}.${type}` : `/icons/images/${name}.${type}`}
  //             alt={`${name}-icon`}
  //             loading="lazy"
  //             className={className}
  //             // onError={(e) => handleOnError(e)}
  //         />
  //     </>
  // );
};

export default Icon;

const SD_IconImg = styled.img<ICustomizeOptions>`
  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  cursor: ${({ cursor }) => cursor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const SD_ColorIcon = styled.div<ICustomizeOptions>`
  transition: background-color ${({ duration }) => duration}ms;

  width: ${({ width }) => width};
  min-width: ${({ width }) => width};
  max-width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ height }) => height};
  max-height: ${({ height }) => height};
  cursor: ${({ cursor }) => cursor};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  background: ${({ color }) => color};
  mask-image: url(${(props) => props['data-src']});
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
`;
