import { useState } from 'react';

import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import useSystem from 'coreSrc/base/systemManager/useSystem';
import useUserSystem from 'coreSrc/base/userSystemManager/user/useUserSystem';

import delay from 'coreSrc/core/js/utils/delay/delay';

import isPc from 'hostSrc/_initDatas/isPc';
import Icon from 'hostSrc/comp/Icon';
import LoadingOverlay from 'hostSrc/comp/LoadingOverlay';

import Grid from 'jhSrc/components/Grid';
import Text from 'jhSrc/components/Text';

import styled from 'styled-components';

const SignUpScreen = dynamic(() => import('hostSrc/comp/SignUpScreen'), { ssr: false });

interface ISignInDrawerProps {
  className?: string;
  onClickClose?: () => void;
  onSuccessSignIn?: () => void;
  closeWhenClickOverlay?: boolean;
  hideAppleLogin?: boolean;
}

let SignInDrawer: React.FunctionComponent<ISignInDrawerProps> = (props) => {
  const router = useRouter();

  const system = useSystem();
  const userSystem = useUserSystem();
  const [isLoading, setIsLoading] = useState(false);

  async function onKakaoLogin() {
    try {
      if (['hana', 'nh', 'nhAllOne'].includes(system.partner.getType())) {
        router.replace('/oauth/kakao');
        return;
      }

      await userSystem.auth.kakaoLogin();
      props.onClickClose?.();
      props.onSuccessSignIn?.();
    } catch (error) {
      if (error.tag === 'UNREGISTED_USER') {
        userSystem.cancelLoading();
        props.onClickClose?.();

        await delay(300);

        return system.dialog.open(
          <SignUpScreen
            registerToken={error.token}
            onClose={props.onClickClose}
            onSuccessSignIn={props.onSuccessSignIn}
          />
        );
      }

      userSystem.cancelLoading();

      throw error;
    }
  }

  async function onAppleLogin() {
    setIsLoading(true);
    await userSystem.auth.appleLogin();

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }

  function onClose(e?: React.MouseEvent<HTMLElement>) {
    e?.stopPropagation();
    if (props.closeWhenClickOverlay) {
      props.onClickClose?.();
    }
  }

  return (
    <Grid
      className={props.className}
      templateRows="1fr max-content"
    >
      <div onClick={onClose}></div>

      <Grid
        as="ul"
        gap="16px"
        background="#fff"
        style={
          isPc(system) ||
          (system.router.pathname.includes('experience') && !system.detector.isMobile) ||
          !system.detector.isMobile
            ? PC_STYLE
            : {}
        }
      >
        <Grid as="li">
          <FillButton
            background="#FEE500"
            onClick={onKakaoLogin}
          >
            <Icon
              name="kakao-logo"
              width="15px"
              height="15px"
            />

            <Text
              color="rgba(0, 0, 0, 0.85)"
              size="15px"
            >
              카카오톡으로 로그인
            </Text>
          </FillButton>
        </Grid>

        {!props.hideAppleLogin && (
          <Grid as="li">
            <FillButton
              background="#000"
              onClick={() => {
                if (isLoading) return;
                onAppleLogin();
              }}
            >
              {isLoading && <LoadingOverlay />}
              <Icon
                name="apple-logo"
                width="15px"
                height="15px"
              />
              <Text
                color="#fff"
                size="15px"
              >
                Apple 로그인
              </Text>
            </FillButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

SignInDrawer = styled(SignInDrawer)`
  pointer-events: none;

  width: 100%;
  height: 100%;

  & > ul {
    pointer-events: all;

    padding: 24px 16px;

    border-radius: 8px 8px 0 0;
  }
`;

SignInDrawer.defaultProps = {
  closeWhenClickOverlay: true,
};

export default SignInDrawer;

const PC_STYLE = {
  position: 'fixed' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%,-50%)',
  width: '500px',
  transition: 'none',
  borderRadius: '10px',
  overflow: 'hidden',
  background: '#fff',
};

interface IFillButtonProps {
  className?: string;
  background?: string;
  onClick?: () => void;
}

let FillButton: React.FunctionComponent<IFillButtonProps> = (props) => {
  return (
    <button
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

FillButton = styled(FillButton)`
  display: grid;
  gap: 10px;
  place-content: center;
  place-items: center;
  grid-template-columns: max-content max-content;

  height: 52px;

  border-radius: 12px;

  background: ${({ background }) => background ?? 'transparent'};
`;
