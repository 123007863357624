import { richFetchJson } from '../../../../../coreSrc/core/js/utils/richFetch';
import isPc from '../../../../_initDatas/isPc';

const sellingFetchJson = (url, option) => {
  return richFetchJson(url, option)
    .then((v) => {
      if (v['errors']) {
        console.log('sellingFetchJson에러2', v);
        console.log('sellingFetchJson에러2', v['errors']);
      } else return v;
    })
    .catch((v) => {
      console.log('sellingFetchJson에러', v);
    });
};
sellingFetchJson.requestUnderReview = (sellDanjiId, actions) => {
  return sellingFetchJson(`/api/user/sell/request-review?sellDanjiId=${sellDanjiId}`, {
    method: 'POST',
  }).then((v) => {
    window.dispatchEvent(new CustomEvent('updateList'));
  });
};
sellingFetchJson.deleteSaleItem = (sellDanjiId, actions) => {
  return sellingFetchJson(`/api/user/sell/goods/${sellDanjiId}`, { method: 'DELETE' }).then((v) => {
    window.dispatchEvent(new CustomEvent('updateList'));
  });
};
sellingFetchJson.updateList = (sys, state, actions, payload) => {
  const pcYn = isPc(sys);
  if (pcYn) {
    if (state.pcMenuIDX === 0) {
      actions.requestAllItemList(payload);
    } else {
      if (state.menuIDX === 0) {
        actions.requestBrokerItemList(payload);
      } else {
        actions.requestUserItemList(payload);
      }
    }
  } else {
    const pathName = sys.router.pathname;
    console.log('sys', sys, pathName, payload);
    switch (pathName) {
      case '/selling/sellingMyItem':
        actions.requestUserItemList(payload);
        break;
      case '/selling/sellingAllItem':
        actions.requestAllItemList(payload);
        break;
      case '/selling/sellingBrokerItem':
        actions.requestBrokerItemList(payload);
        break;
      default:
        actions.requestBrokerItemList(payload);
        actions.requestUserItemList(payload);
        break;
    }
  }
};

export default sellingFetchJson;
