/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type FeedType = (typeof FeedType)[keyof typeof FeedType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeedType = {
  None: 'None',
  RealTransactionPrice: 'RealTransactionPrice',
  LowestListingPrice: 'LowestListingPrice',
  CommunityHotArticle: 'CommunityHotArticle',
  MorningRealEstateNews: 'MorningRealEstateNews',
  LeadingDanjiPriceTrend: 'LeadingDanjiPriceTrend',
  Parcel: 'Parcel',
  RegionRealEstateTrend: 'RegionRealEstateTrend',
  NewAuction: 'NewAuction',
  RealBuildingTransactionPrice: 'RealBuildingTransactionPrice',
  RealLandTransactionPrice: 'RealLandTransactionPrice',
  AggregatedRealTransactionPrice: 'AggregatedRealTransactionPrice',
  RegionLeaderRealTransactionPrice: 'RegionLeaderRealTransactionPrice',
  AggregatedLowestListingPrice: 'AggregatedLowestListingPrice',
  RegionLeaderLowestListingPrice: 'RegionLeaderLowestListingPrice',
} as const;
