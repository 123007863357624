import { getApiSellMyGoodsList } from 'hostSrc/apis/endpoints/realty-user-sell/realty-user-sell';
import { rest } from 'msw';
import { createUrl } from './utils/createUrl';

type ResponseType = Awaited<ReturnType<typeof getApiSellMyGoodsList>>;

const mockGetApiSellMyGoodsList = rest.get(createUrl('/api/sell/my-goods-list'), (req, res, ctx) => {
  // const mockData: ResponseType = apiResponse({
  //   isOpenAgree: false,
  //   aptGoodsList: [
  //     {
  //       danjiId: 'a5GtDrn',
  //       danji: '하남',
  //       sellGoodsId: 619,
  //       sellGoodsStage: SellGoodsStageType.WRITE_COMPLETED,
  //       identityVerificationType: IdentityVerificationYnEnum.NO,
  //       tradeType: TradeType.Meme,
  //       price: 12,
  //       monthlyPrice: null,
  //       dong: '1',
  //       ho: '808',
  //       floor: 8,
  //       moveInDate: '2023-01-27T10:10:41+00:00',
  //       bjdInfoDto: {
  //         sd: '경기',
  //         sgg: '하남시',
  //         emd: '신장동',
  //         bjdCode: '4145010600',
  //         location: {
  //           x: 127.209586513273,
  //           y: 37.5387902964118,
  //         },
  //       },
  //       location: {
  //         x: 127.207418,
  //         y: 37.540301,
  //       },
  //       sellDanjiState: SellGoodsStageType.WRITE_COMPLETED,
  //       userId: '1lzUUJkAxRNL',
  //       image: null,
  //       isTenant: false,
  //       createDtm: '2023-01-27T10:11:04+00:00',
  //       pyeong: 16,
  //       // brokerList: null,
  //       viewInfoDto: {
  //         viewCount: 0,
  //         checkCount: 0,
  //         // chattingCount: 0,
  //         // matchingCount: 0,
  //       },
  //       viewBrokerList: null,
  //     },
  //     {
  //       danjiId: 'a6jGQXZ',
  //       danji: '반포자이',
  //       sellGoodsId: 10096,
  //       sellGoodsStage: SellGoodsStageType.COMPLETE,
  //       identityVerificationType: IdentityVerificationYnEnum.NO,
  //       tradeType: TradeType.Meme,
  //       price: 310000,
  //       monthlyPrice: 0,
  //       dong: '111',
  //       ho: '1111',
  //       floor: 29,
  //       moveInDate: '2023-10-30T15:00:00+00:00',
  //       bjdInfoDto: {
  //         sd: '서울',
  //         sgg: '서초구',
  //         emd: '반포동',
  //         bjdCode: '1165010700',
  //         location: {
  //           x: 127.013359062646,
  //           y: 37.5051446767473,
  //         },
  //       },
  //       location: {
  //         x: 127.013605,
  //         y: 37.507538,
  //       },
  //       sellDanjiState: SellGoodsStageType.COMPLETE,
  //       userId: '1lzUUJkAxRNL',
  //       image: '63833723780716296079',
  //       isTenant: false,
  //       createDtm: '2023-10-24T05:59:05+00:00',
  //       pyeong: 35,
  //       // brokerList: null,
  //       viewInfoDto: {
  //         viewCount: 0,
  //         checkCount: 0,
  //         // chattingCount: 0,
  //         // matchingCount: 0,
  //       },
  //       viewBrokerList: null,
  //     },
  //     {
  //       danjiId: 'a6jGQXZ',
  //       danji: '반포자이',
  //       sellGoodsId: 10097,
  //       sellGoodsStage: SellGoodsStageType.SALE,
  //       identityVerificationType: IdentityVerificationYnEnum.NO,
  //       tradeType: TradeType.Meme,
  //       price: 310000,
  //       monthlyPrice: 0,
  //       dong: '111',
  //       ho: '1111',
  //       floor: 29,
  //       moveInDate: '2023-10-30T15:00:00+00:00',
  //       bjdInfoDto: {
  //         sd: '서울',
  //         sgg: '서초구',
  //         emd: '반포동',
  //         bjdCode: '1165010700',
  //         location: {
  //           x: 127.013359062646,
  //           y: 37.5051446767473,
  //         },
  //       },
  //       location: {
  //         x: 127.013605,
  //         y: 37.507538,
  //       },
  //       sellDanjiState: SellGoodsStageType.SALE,
  //       userId: '1lzUUJkAxRNL',
  //       image: '63833724062942784098',
  //       isTenant: false,
  //       createDtm: '2023-10-24T06:02:03+00:00',
  //       pyeong: 35,
  //       // brokerList: null,
  //       viewInfoDto: {
  //         viewCount: 0,
  //         checkCount: 0,
  //         // chattingCount: 0,
  //         // matchingCount: 0,
  //       },
  //       viewBrokerList: null,
  //     },
  //   ],
  //   officetelGoodsList: [
  //     {
  //       danjiId: 'a9tWEUz',
  //       danji: '신림큐브',
  //       sellGoodsId: 10122,
  //       sellGoodsStage: SellGoodsStageType.SALE,
  //       identityVerificationType: IdentityVerificationYnEnum.NO,
  //       tradeType: TradeType.Meme,
  //       price: 131313,
  //       monthlyPrice: 0,
  //       dong: '111',
  //       ho: '1313',
  //       floor: 3,
  //       moveInDate: '2023-11-29T15:00:00+00:00',
  //       bjdInfoDto: {
  //         sd: '서울',
  //         sgg: '관악구',
  //         emd: '신림동',
  //         bjdCode: '1162010200',
  //         location: {
  //           x: 126.927123713685,
  //           y: 37.4874127684484,
  //         },
  //       },
  //       location: {
  //         x: 126.932231,
  //         y: 37.485692,
  //       },
  //       sellDanjiState: SellGoodsStageType.SALE,
  //       userId: '1lzUUJkAxRNL',
  //       image: null,
  //       isTenant: false,
  //       createDtm: '2023-11-06T15:15:32+00:00',
  //       pyeong: 11,
  //       // brokerList: null,
  //       viewInfoDto: {
  //         viewCount: 0,
  //         checkCount: 0,
  //         // chattingCount: 0,
  //         // matchingCount: 0,
  //       },
  //       viewBrokerList: null,
  //     },
  //   ],
  // });
  return res(ctx.status(200), ctx.json<ResponseType>(null));
});

export default mockGetApiSellMyGoodsList;
