import type { TmpApiResponse } from 'hostSrc/apis/mutator/customFetch';

const apiResponse = <T>(result: T): TmpApiResponse<T> => ({
  version: null,
  statusCode: 200,
  message: null,
  isError: null,
  responseException: null,
  result,
});

export default apiResponse;
