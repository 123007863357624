import {
  ICreateNotificationDanjiSettingPayload,
  ICreateNotificationParcelSettingPayload,
} from 'jhSrc/apis/notification';

import type { TUserSystemActions } from '../useUserSystemReducer';

import type {
  TUserNotificationDanjiSetting,
  TUserNotificationParcelSetting,
} from './UserNotification';

import {
  updateNotificationDanjiSetting,
  updateNotificationParcelSetting,
} from 'jhSrc/apis/notification';

import UserNotification from './UserNotification';

function useNotificationReducer(notification: UserNotification, actions: TUserSystemActions) {
  const hasSetting = (id: string) =>
    notification.setting.list.find((setting) => setting.id === id) ? true : false;

  const getSettingList = () => notification.setting.list;
  const getStorySettingList = () =>
    notification.setting.list.filter((setting) => {
      if (setting.type === 'danji') return true;
      if (setting.type === 'parcel') return true;
      return false;
    });
  const getDanjiSettingList = () =>
    notification.setting.list.filter(
      (setting) => setting.type === 'danji'
    ) as TUserNotificationDanjiSetting[];

  const setDanjiSetting = async (payload: ICreateNotificationDanjiSettingPayload) => {
    await updateNotificationDanjiSetting(payload);
    await actions.update();
  };

  const getParcelSettingList = () =>
    notification.setting.list.filter(
      (setting) => setting.type === 'parcel'
    ) as TUserNotificationParcelSetting[];
  const setParcelSetting = async (payload: ICreateNotificationParcelSettingPayload) => {
    await updateNotificationParcelSetting(payload);
    await actions.update();
  };

  return {
    hasSetting,
    getSettingList,

    getStorySettingList,

    getDanjiSettingList,
    setDanjiSetting,

    getParcelSettingList,
    setParcelSetting,
  };
}

export default useNotificationReducer;
