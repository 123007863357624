import UserNotification from './notification/UserNotification';
import UserProfile from './profileManager/UserProfile';
import UserAuth from './authManager/UserAuth';
import UserFavorites from './favorites/UserFavorites';

export default class User {
  notification = new UserNotification();
  favorites = new UserFavorites();
  profile = new UserProfile();
  auth = new UserAuth();

  constructor(userFromServer?: any) {
    if (userFromServer) {
      this.notification = userFromServer.notification;
      this.profile = userFromServer.profile;
      this.auth = userFromServer.auth;
      this.favorites = userFromServer.favorites;
    }
  }
}
