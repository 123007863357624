import type { RouteKey } from 'coreSrc/base/systemManager/routeManager';

import createRichgoMapPath from 'hostSrc/pages/realty/richgoMap/createRichgoMapPath';

import createRealtyFeedHomeScreenPath from 'jhSrc/screens/RealtyFeedHomeScreen/createRealtyFeedHomeScreenPath';

export type GnbItem = {
  key: RouteKey;
  href: string;
  label: string;
  icon: string;
  activeIcon: string;
  slug?: Record<string, string>;
  hideSamsung?: boolean;
  samsungOnly?: boolean;
  hideNh?: boolean;
  hideZum?: boolean;
  pathMatcher?: (pathname: string) => boolean;
  onlyDev?: boolean;
};

const gnbItems: GnbItem[] = [
  {
    key: 'myHouses',
    href: createRealtyFeedHomeScreenPath('main'),
    label: '홈',
    icon: 'gnb-home',
    activeIcon: 'gnb-home.darkgray',
    samsungOnly: true,
    hideNh: true,
    hideZum: true,
  },
  {
    key: 'home',
    href: createRealtyFeedHomeScreenPath('main'),
    label: '홈',
    icon: 'gnb-home',
    activeIcon: 'gnb-home.darkgray',
    hideSamsung: true,
    hideNh: true,
    hideZum: false,
  },
  // {
  //   key: 'parcelHome',
  //   href: createParcelHomeScreenPath(),
  //   label: '청약',
  //   icon: 'gnb-parcel',
  //   activeIcon: 'gnb-parcel.darkgray',
  //   // samsungOnly: true,
  // },
  // {
  //   key: 'todayRealty',
  //   href: '/todayRealty',
  //   label: '오늘의부동산',
  //   icon: 'gnb-todayRealty',
  //   activeIcon: 'gnb-todayRealty_dark',
  //   // onlyDev: true,
  // },
  {
    key: 'signal',
    href: '/signal',
    label: '부동산시그널',
    icon: 'gnb-signal',
    activeIcon: 'gnb-signal.darkgray',
    // onlyDev: true,
  },
  {
    key: 'auction',
    href: '/auction',
    label: '경매',
    icon: 'gnb-auction',
    activeIcon: 'gnb-auction.darkgray',
    hideZum: true,
    // samsungOnly: true,
  },
  {
    key: 'map',
    href: createRichgoMapPath(),
    label: '아파트',
    icon: 'gnb-map',
    activeIcon: 'gnb-map.darkgray',
    hideNh: false,
    hideZum: false,
  },
  {
    key: 'landMap',
    href: '/landMap',
    label: '토지건물',
    icon: 'gnb-land-map',
    activeIcon: 'gnb-land-map.darkgray',
    hideNh: false,
    hideZum: false,
    // onlyDev: true,
  },
  {
    key: 'selling',
    href: '/selling',
    label: '매물',
    icon: 'gnb-selling',
    activeIcon: 'gnb-selling.darkgray',
    hideNh: false,
    hideZum: true,
    pathMatcher: (pathname: string) => {
      if (pathname.includes('selling')) return true;
    },
  },
];

export default gnbItems;
