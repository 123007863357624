import apiResponse from './utils/apiResponse';
import { getApiSellDetailAptSellDanjiId } from 'hostSrc/apis/endpoints/realty-user-sell/realty-user-sell';
import { rest } from 'msw';
import { createUrl } from './utils/createUrl';
import {
  BoolThreeType,
  IdentityVerificationYnEnum,
  RadarDirectionType,
  RegisterUserType,
  SellGoodsAuthorityType,
  SellGoodsCategoryType,
  SellGoodsStageType,
  TradeType,
} from 'hostSrc/apis/model';

type ResponseType = Awaited<ReturnType<typeof getApiSellDetailAptSellDanjiId>>;

const mockGetApiSellDetailAptSellDanjiId = rest.get<
  undefined,
  { sellDanjiId: string },
  ResponseType
  /* NOTE: kunhee.lim
   *  :sellDanjiId 뒤에 (\\d+) 정규식을 붙인 이유는
   *  유사한 형태의 url도 캐치되기 때문에
   *  :sellDanjiId 자리는 숫자만 캐치하도록 구분하기 위함. */
>(createUrl('/api/sell/detail/apt/:sellDanjiId(\\d+)'), (req, res, ctx) => {
  const mockData: ResponseType = apiResponse({
    commonDto: {
      remainFreeTicketCount: 2,
      isCheckingTelReq: false,
      isOpenAgree: false,
      sellGoodsAuthorityType: SellGoodsAuthorityType.GOODS_OWNER,
      pyeong: 28,
      sellGoodsStage: SellGoodsStageType.SALE,
      sellGoodsId: 10011,
      sellGoodsCategoryType: SellGoodsCategoryType.APT_ALL,
      isFavorite: false,
      userId: 'kAg5qX9DKgAj',
      identityVerificationType: IdentityVerificationYnEnum.NO,
      imagesList: [
        'https://images-dev.richgo.ai/images/thumbnail/community/3R9eIv4sMHvr/63782236337396318178_normal',
        'https://images-dev.richgo.ai/images/thumbnail/community/3R9eIv4sMHvr/63782236337613016453_normal',
        'https://images-dev.richgo.ai/images/thumbnail/community/3R9eIv4sMHvr/6378223633773708336_normal',
      ],
      bjdInfoDto: {
        sd: '경기',
        sgg: '고양시 덕양구',
        emd: '삼송동',
        bjdCode: '4128111100',
        location: {
          x: 126.890252448451,
          y: 37.6517834330055,
        },
      },
      price: 324000,
      monthlyPrice: 0,
      tradeType: TradeType.Meme,
      createdDtm: '2023-09-13T06:44:40+00:00',
      registerUserType: RegisterUserType.BROKER,
      isOverridePrice: true,
      isOverridePriceDesc:
        '2천만원까지 조정이 가능하구, 8월 13일까지 매매가 가능하다면 조금 더 조정이 가능합니다.',
      isTenant: true,
      tenantInfo: {
        tenantDeposit: 25000,
        tenantExpiredDate: '2023-09-13T06:44:40+00:00',
        tenantMonthlyPrice: 20,
        tenentTradeType: TradeType.ShortTermRental,
      },
      moveInDate: '2023-09-11T08:40:22+00:00',
      roomCount: 2,
      topFloor: null,
      maintenanceCostDto: {
        maintenanceCost: 50,
        maintenanceList: ['수도료', '전기료'],
        noMaintenanceList: ['인터넷', '가스비'],
      },
      loanType: BoolThreeType.YES,
      petType: BoolThreeType.CHECK,
      parkingType: BoolThreeType.NO,
      warrantyInsuranceType: BoolThreeType.NO,
      facility: ['학교', '학원', '도서관'],
      advantagesDesc: '',
      // advantagesDesc:
      //   '19.2억 희망하지만, 가격은 협의 가능합니다. 매물은 깨끗하고, 최근 화장실 인테리어 했어요. 너무나 살기 좋은 매물입니다. 그리고 또 로켓배송이나 새벽배송도 다 되고, 개인택배 보관서비스도 있어요.\n' +
      //   '\n' +
      //   '현관에는 세대별로 캡스 CCTV가 설치되어있어 휴대폰 어플 설치 후 실시간 확인할 수 있고, 여자 혼자 살기에도 안심되고 안전합니다.',
      advantagesTags: ['건물', '교통', '테라스'],
      // advantagesTags: [],
      location: {
        x: 126.8874,
        y: 37.650636,
      },
      bjdAddress: '경기도 고양시 덕양구 삼송동 311',
      roadAddress: '경기도 고양시 덕양구 세솔로 73',
      depositViewBroker: false,
      deposit: null,
      depositYn: false,
    },
    sellGoodsIsOpenAgreeType: 'CHECKING',
    restRoomCount: null,
    danji: '삼송아이파크2차',
    danjiId: 'a2jNvMl',
    dong: '101',
    ho: '502',
    pyeong: 28,
    areaDto: {
      pyeongType: 28,
      minSupplyArea: null,
      maxSupplyArea: '100',
      minPrivateArea: '100',
      maxPrivateArea: null,
    },
    floor: 14,
    topFloor: 23,
    minOfferPrice: 325000,
    directionType: RadarDirectionType.EAST,
    sellGoodsViewInfoOutputDto: {
      /** 조회수 */
      viewCount: 3,
      /** 채팅수 */
      chattingCount: 2,
      /** 매칭수 */
      matchingCount: 1,
    },
    sellGoodsPriceHistory: [
      {
        /** 날짜 */
        createDtm: '2023-09-11T08:40:22+00:00',
        /** 가격 */
        price: 14001200,
        /** 월세가격 */
        monthlyPrice: 30,
        /** 갭 -  월세는 제외 */
        gap: 14000,
      },
      {
        /** 날짜 */
        createDtm: '2023-09-11T08:40:22+00:00',
        /** 가격 */
        price: 41200,
        /** 월세가격 */
        monthlyPrice: 30,
        /** 갭 -  월세는 제외 */
        gap: -9000,
      },
      {
        /** 날짜 */
        createDtm: '2023-09-11T08:40:22+00:00',
        /** 가격 */
        price: 0,
        /** 월세가격 */
        monthlyPrice: 30,
        /** 갭 -  월세는 제외 */
        gap: -1000,
      },
      {
        /** 날짜 */
        createDtm: '2023-09-11T08:40:22+00:00',
        /** 가격 */
        price: -200,
        /** 월세가격 */
        monthlyPrice: 30,
        /** 갭 -  월세는 제외 */
        gap: -14120,
      },
    ],
    keywords: ['408세대', '준공23년', '초품아'],
    userPhoneNo: '01012345555',
    // estimateState: SellEstimateState.APPROVAL,
    estimateState: null,
  });

  return res(ctx.status(200), ctx.json<ResponseType>(mockData));
});

export default mockGetApiSellDetailAptSellDanjiId;
