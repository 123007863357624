import { setCookie } from '../cookie';

const A_YEAR_SECOND = 365 * 24 * 60 * 60;

const DEFAULT_MAX_AGE = 2 * A_YEAR_SECOND;
const DEFAULT_PATH = '/';
const DEFAULT_SAMESITE = 'Lax';

interface IDocumentCookieOptions {
  /** 단위: 초 */
  maxAge?: number;
  path?: string;
  samesite?: string;
}

class DocumentCookie {
  get(key: string) {
    const jsonCookie = this.toJSON();
    const value = jsonCookie[key] ?? null;
    return value;
  }

  set(key: string, value: string | null, options?: IDocumentCookieOptions) {
    const cookieJson = {
      [key]: value,
      'max-age': options?.maxAge ?? DEFAULT_MAX_AGE,
      path: options?.path ?? DEFAULT_PATH,
      samesite: options?.samesite ?? DEFAULT_SAMESITE,
    };
    const cookie = Object.entries(cookieJson)
      .map((entry) => entry.join('='))
      .join('; ');
    setCookie(cookie);
  }

  delete(key: string) {
    setCookie(`${key}=;max-age=0;path=${DEFAULT_PATH}`);
  }

  deleteAll() {
    const jsonCookie = this.toJSON();
    for (const key in jsonCookie) {
      setCookie(`${key}=;max-age=0;path=${DEFAULT_PATH}`);
    }
  }

  toJSON(): Record<string, string | null> {
    if (document.cookie === '') return {};
    const stringEntries = document.cookie.split(';');

    const jsonEntries = stringEntries.reduce((accu, stringEntry) => {
      const key = stringEntry.slice(0, stringEntry.indexOf('='));
      const value = stringEntry.slice(key.length + 1).trim() ?? null;

      accu[key.trim()] = value;

      return accu;
    }, {});

    return jsonEntries;
  }

  toString() {
    return document.cookie;
  }
}

/**
 * @description
 * ```plaintext
 * document.cookie를 다룰 때 사용하는 DocumentCookie 클래스의 인스턴스 객체입니다.
 * ```
 */
const documentCookie = new DocumentCookie();

export default documentCookie;
