/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type MapAnalysisLandCategoryNameType =
  (typeof MapAnalysisLandCategoryNameType)[keyof typeof MapAnalysisLandCategoryNameType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MapAnalysisLandCategoryNameType = {
  미지정: '미지정',
  전: '전',
  답: '답',
  과수원: '과수원',
  목장용지: '목장용지',
  임야: '임야',
  광천지: '광천지',
  염전: '염전',
  대: '대',
  공장용지: '공장용지',
  학교용지: '학교용지',
  주차장: '주차장',
  주유소용지: '주유소용지',
  창고용지: '창고용지',
  도로: '도로',
  철도용지: '철도용지',
  제방: '제방',
  하천: '하천',
  구거: '구거',
  유지: '유지',
  양어장: '양어장',
  수도용지: '수도용지',
  공원: '공원',
  체육용지: '체육용지',
  유원지: '유원지',
  종교용지: '종교용지',
  사적지: '사적지',
  묘지: '묘지',
  잡종지: '잡종지',
  기타: '기타',
} as const;
