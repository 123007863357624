import { factoryCall } from 'coreSrc/base/pageStore';

import t from './actionTypes';
import sellingFetchJson from '../../pages/realty/selling/helper/sellingFetchJson';

const calls = {
  [t.REQUEST_USER_ITEM_LIST]: factoryCall(t.REQUEST_USER_ITEM_LIST, async (payload) => {
    // const resJson = await sellingFetchJson('/api/user/sell/goodsList/SELF');
    // return resJson.result.danjiList;
  }),
  // [t.REQUEST_ALL_ITEM_LIST]: factoryCall(t.REQUEST_ALL_ITEM_LIST, async (payload) => {
  //   const resJson = await sellingFetchJson('/api/user/sell/all-goods-list', {
  //     method: 'POST',
  //     body: {
  //       // 'sellTypeList': [
  //       //   'APT',
  //       // ],
  //       wishTypeList: payload?.wishTypeList || [],
  //       sd: payload?.sd,
  //       sgg: payload?.sgg,
  //       emdList: payload?.emdList,
  //       // 'state': 'WRITE_COMPLETED',
  //       state: payload?.state,
  //       wishPrice: payload?.wishPrice,
  //       // 'wishDate': {
  //       //   // 'min': '2022-08-17T02:39:05.223Z',
  //       //   // 'max': '2022-08-17T02:39:05.223Z',
  //       // },
  //       isNotViewDelete: payload?.isNotViewDelete, // 삭제된놈들 보기
  //       order: payload?.order,
  //       limit: payload?.limit || 10,
  //       offset: payload?.offset || 0,
  //     },
  //   });
  //   return resJson.result.danjiList;
  // }),
  [t.REQUEST_ALL_ITEM_LIST]: factoryCall(t.REQUEST_ALL_ITEM_LIST, async (payload) => {
    const resJson = await sellingFetchJson('/api/user/sell/all-goods-list', {
      method: 'POST',
      body: {
        // 'sellTypeList': [
        //   'APT',
        // ],
        wishTypeList: payload?.wishTypeList || [],
        sd: payload?.sd,
        sgg: payload?.sgg,
        emdList: payload?.emdList,
        // 'state': 'WRITE_COMPLETED',
        state: payload?.state,
        wishPrice: payload?.wishPrice,
        // 'wishDate': {
        //   // 'min': '2022-08-17T02:39:05.223Z',
        //   // 'max': '2022-08-17T02:39:05.223Z',
        // },
        isNotViewDelete: payload?.isNotViewDelete, // 삭제된놈들 보기
        order: payload?.order,
        limit: payload?.limit || 10,
        offset: payload?.offset || 0,
      },
    });
    return resJson.result.danjiList;
  }),
  [t.REQUEST_BROKER_ITEM_LIST]: factoryCall(t.REQUEST_BROKER_ITEM_LIST, async (payload) => {
    console.log(payload);
    const resJson = await sellingFetchJson('/api/user/sell/broker-list', {
      method: 'POST',
      body: {
        sellBrokerListType: payload?.code,
        sellEstimateStateList: payload?.sellEstimateStateList,
        sellRequestStateList: payload?.sellRequestStateList,
        order: payload?.order,
        limit: 100,
        offset: 0,
      },
    });
    return {
      result: resJson.result,
      targetDataKey: payload?.targetDataKey,
    };
  }),
};

export default calls;
