/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */
import type { ActivityModel, ApiResponse } from '../../model';
import { customFetch } from '../../mutator/customFetch';
import type { BodyType, ErrorType } from '../../mutator/customFetch';

import { useMutation } from '@tanstack/react-query';
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary 유저 활동 로그
 */
export const postApiActivity = (
  activityModel: BodyType<ActivityModel>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/api/activity',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: activityModel,
    },
    options
  );
};

export const getPostApiActivityMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiActivity>>,
    TError,
    { data: BodyType<ActivityModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiActivity>>,
  TError,
  { data: BodyType<ActivityModel> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiActivity>>,
    { data: BodyType<ActivityModel> }
  > = (props) => {
    const { data } = props ?? {};

    return postApiActivity(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiActivityMutationResult = NonNullable<Awaited<ReturnType<typeof postApiActivity>>>;
export type PostApiActivityMutationBody = BodyType<ActivityModel>;
export type PostApiActivityMutationError = ErrorType<unknown>;

/**
 * @summary 유저 활동 로그
 */
export const usePostApiActivity = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiActivity>>,
    TError,
    { data: BodyType<ActivityModel> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiActivity>>,
  TError,
  { data: BodyType<ActivityModel> },
  TContext
> => {
  const mutationOptions = getPostApiActivityMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary 유저 활동 로그 v2
 */
export const postApiActivityV2 = (
  postApiActivityV2Body: BodyType<unknown>,
  options?: SecondParameter<typeof customFetch>
) => {
  return customFetch<ApiResponse>(
    {
      url: '/api/activity/v2',
      method: 'POST',
      headers: { 'Content-Type': 'application/json-patch+json' },
      data: postApiActivityV2Body,
    },
    options
  );
};

export const getPostApiActivityV2MutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiActivityV2>>,
    TError,
    { data: BodyType<unknown> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postApiActivityV2>>,
  TError,
  { data: BodyType<unknown> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postApiActivityV2>>,
    { data: BodyType<unknown> }
  > = (props) => {
    const { data } = props ?? {};

    return postApiActivityV2(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostApiActivityV2MutationResult = NonNullable<
  Awaited<ReturnType<typeof postApiActivityV2>>
>;
export type PostApiActivityV2MutationBody = BodyType<unknown>;
export type PostApiActivityV2MutationError = ErrorType<unknown>;

/**
 * @summary 유저 활동 로그 v2
 */
export const usePostApiActivityV2 = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postApiActivityV2>>,
    TError,
    { data: BodyType<unknown> },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postApiActivityV2>>,
  TError,
  { data: BodyType<unknown> },
  TContext
> => {
  const mutationOptions = getPostApiActivityV2MutationOptions(options);

  return useMutation(mutationOptions);
};
