/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type RealEstateTypeEnum = (typeof RealEstateTypeEnum)[keyof typeof RealEstateTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RealEstateTypeEnum = {
  지식산업센터: '지식산업센터',
  아파트분양권: '아파트분양권',
  아파트: '아파트',
  상가: '상가',
  토지: '토지',
  단독_다가구: '단독_다가구',
  원룸: '원룸',
  상가주택: '상가주택',
  빌라: '빌라',
  오피스텔: '오피스텔',
  사무실: '사무실',
  건물: '건물',
  재건축: '재건축',
  전원주택: '전원주택',
  재개발: '재개발',
  공장_창고: '공장_창고',
  오피스텔분양권: '오피스텔분양권',
  _: '_',
} as const;
