import type { TUserSystemActions } from '../useUserSystemReducer';

import type UserAuth from './UserAuth';

import authUtils from 'coreSrc/base/userSystemManager/user/authManager/utils';
import richFetch from 'coreSrc/core/js/utils/richFetch';
import { setCookie } from 'coreSrc/core/js/utils/cookie';

import AppleLoginHelper from 'jhSrc/helpers/AppleLoginHelper';
import fcmTokenManager from 'jhSrc/utils/fcmTokenManager';

import { RICHGO_API_ACCESS_TOKEN_KEY, RICHGO_API_REFRESH_TOKEN_KEY } from './const';

export default function useAuthReducer(auth: UserAuth, actions: TUserSystemActions) {
  function getIsLoggedIn() {
    return auth.isLoggedIn;
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  async function login() {
    await authUtils.oauth.login();
    await actions.update();
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  async function kakaoLogin() {
    await authUtils.oauth.kakaoLogin();
    await actions.update();
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  function kakaoAgreements(scope: string) {
    authUtils.oauth.kakaoAgreements(scope);
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  async function appleLogin() {
    AppleLoginHelper.setBeforeUrl();
    location.replace(AppleLoginHelper.getAppleLoginUrl());
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  async function logout() {
    fcmTokenManager.logout();

    setCookie(`${RICHGO_API_ACCESS_TOKEN_KEY}=;path=/;max-age=0`);
    setCookie(`${RICHGO_API_REFRESH_TOKEN_KEY}=;path=/;max-age=0`);

    await actions.update();
  }

  /** 클라이언트 사이드에서만 호출 가능합니다 */
  async function signout() {
    actions.setLoading(true);
    const isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
    const fcmToken = sessionStorage.getItem('fcmToken') || null;
    const deviceId = sessionStorage.getItem('deviceId') || null;

    const urlSearchParams = fcmToken
      ? `?isAndroid=${isAndroid}&fcmToken=${fcmToken}&deviceId=${deviceId}`
      : '';
    await richFetch('/user/V2/withdraw' + urlSearchParams, { method: 'DELETE' });
    setCookie(`${RICHGO_API_ACCESS_TOKEN_KEY}=;path=/;max-age=0`);
    setCookie(`${RICHGO_API_REFRESH_TOKEN_KEY}=;path=/;max-age=0`);
    await actions.update();
  }

  return {
    getIsLoggedIn,
    login,
    kakaoLogin,
    appleLogin,
    kakaoAgreements,
    logout,
    signout,
  };
}
