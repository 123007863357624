/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type OpengoodsTodayPeriodFilter =
  (typeof OpengoodsTodayPeriodFilter)[keyof typeof OpengoodsTodayPeriodFilter];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OpengoodsTodayPeriodFilter = {
  Daily: 'Daily',
  Weekly: 'Weekly',
  Monthly: 'Monthly',
} as const;
