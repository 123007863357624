import { LogEventCategory, LogEventParameters } from 'coreSrc/core/js/utils/analytics/logEvent';

import { TradeType } from 'hostSrc/apis/model';

import { StepKey } from 'hostSrc/pages/realty/selling2.0/addSaleItemFunnel/steps';
import { AddSaleItemFunnelData } from 'hostSrc/pages/realty/selling2.0/addSaleItemFunnel/store';

import CONST_NAVER_MAP_LEVEL from 'mapTestSrc/core/consts/CONST_NAVER_MAP_LEVEL';

import CONST_NAVER_MAP_MODE from '../../../../../mapTestSrc/core/consts/CONST_NAVER_MAP_MODE';
import { APP_ENV } from '../../env';
import { parseCookie } from '../cookie';
import { ButtonClickMap, ButtonClickType, ScreenViewTypeEnum } from './consts';
import log from './log';

import { UAParser } from 'ua-parser-js';

async function logSessionStart() {
  await log('session_start');
}

const getMapMode = (modeData) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore

  const { uiMapState } = modeData || {};
  console.log('uiMapState.markerVisible', modeData);
  const mapMode = modeData
    ? (modeData?.v2_anlyticeYn
        ? '지역분석'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_DISTANCE
        ? '찾아줘_출퇴근'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_EDUCATION
        ? '찾아줘_학군'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_RE
        ? '찾아줘_재건축'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_GAP
        ? '찾아줘_갭'
        : modeData.mapMode === CONST_NAVER_MAP_MODE.RICHGO_SEARCH_BY_PUBLISH
        ? '찾아줘_공시지가'
        : uiMapState?.markerVisible?.auction
        ? '경매'
        : uiMapState?.markerVisible?.building || uiMapState?.markerVisible?.land
        ? '토지_건물'
        : '아파트') + '_mode'
    : 'None';
  const subMapMode = mapMode === '지역분석_mode' ? modeData?.uiMapState?.v3MapMode : undefined;
  return {
    mapMode,
    subMapMode,
  };
};
const getEnv = () => {
  return {
    appYn: !!globalThis?.richgoWebview,
    APP_ENV,
    referrer: document?.referrer || '',
  };
};
async function v2_logGnbMobile(type: string, value: any, pcYn: boolean, asPath) {
  await log(`v2_log_Gnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    from: asPath,
    ...getEnv(),
  });
}
async function v2_logMorePage(type: string, value: any, pcYn: boolean, asPath) {
  await log(`v2_log_MorePage_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    from: asPath,
    ...getEnv(),
  });
}
async function v2_logMorePageDashBoard(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_MorePageDashBoard_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logGnb(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Gnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}
async function v2_logParcelTableView(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_ParcelTableView_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logSceneView(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_SceneView_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logHome(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_Home_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}

async function v2_logAiPredict(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_AiPredict_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logDanjiDetail(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_DanjiDetail_prototype_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logAppDown(type: string, value: any, pcYn: boolean) {
  await log(`v2_log_AppDown_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    ...getEnv(),
  });
}
async function v2_logAnalysisMapMode(type: string, value: any, pcYn: boolean, modeData?) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_AnalysisMapMode_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logMarker(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Marker_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logToggleMarkerClick(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_MarkerVisible_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logFilter(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Filter_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logFloating(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Floating_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logBjdSelect(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_BjdSelect_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logRankingBoard(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_RankingBoard_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}
async function v2_logHedge(type: string, value: any, pcYn: boolean, modeData = undefined) {
  const { mapMode, subMapMode } = modeData
    ? getMapMode(modeData)
    : { mapMode: null, subMapMode: null };
  await log(`v2_log_Hedge_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAdMarker(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = modeData
    ? getMapMode(modeData)
    : { mapMode: null, subMapMode: null };
  await log(`v2_log_AdMarker_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAuctionGnb(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_AuctionGnb_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

async function v2_logAlarm(type: string, value: any, pcYn: boolean, modeData) {
  const { mapMode, subMapMode } = getMapMode(modeData);
  await log(`v2_log_Alarm_${pcYn ? 'pc' : 'mobile'}`, {
    type,
    value,
    mapMode,
    subMapMode,
    ...getEnv(),
  });
}

///////////////////////////////////////////////////////////////////////////////////
function logMapWidgetClick(type: 'selling', zoom: number): Promise<void>;
function logMapWidgetClick(type: 'radar', zoom: number): Promise<void>;
function logMapWidgetClick(type: 'geolocation', zoom: number): Promise<void>;
async function logMapWidgetClick(type: string, zoom?: number, item?: string) {
  if (!globalThis.window) return;
  if (location.pathname !== '/realty/richgoMap') return;
  const payload = { type };
  setLevel();
  setMarker();
  setMapFilter();
  setPriceMode();
  setTradeType();
  await log('map_widget_click', payload);

  function setLevel() {
    if (isNaN(zoom ?? NaN)) return;
    const level = zoom > CONST_NAVER_MAP_LEVEL.MIN_LEVEL_DANJI ? 'danji' : 'region';
    Object.assign(payload, { level });
  }

  function setMarker() {
    if (type === 'marker') {
      const MARKER = {
        danjiRegion: payload['level'],
        officetel: 'officetel',
        parcel: 'parcel',
        broker: 'broker',
        hoje: 'goodnews',
        education: 'education',
        traffic: 'transit',
        facility: 'facility',
        map_type: 'map_type',
      };
      const it = MARKER[item];
      if (it) {
        Object.assign(payload, { item: it });
      }
    }
  }

  function setMapFilter() {
    if (type === 'map_filter') {
      const MAP_FILTER = {
        tradeType: 'trade_type',
        price: 'price',
        pyeongType: 'pyeong_type',
        all: 'all',
        _etc: 'etc',
      };
      const it = MAP_FILTER[item] ?? MAP_FILTER._etc;
      if (it) {
        Object.assign(payload, { item: it });
      }
    }
  }

  function setPriceMode() {
    if (type === 'price_mode') {
      Object.assign(payload, { item });
    }
  }

  function setTradeType() {
    if (type === 'trade_type') {
      Object.assign(payload, { item });
    }
  }
}

async function logDetailScreenView(params: {
  type: string;
  id: string;
  alias: string;
  from: string;
  referenceId?: string;
  referenceAlias?: string;
}) {
  //TODO - 삭제해도 된다고했으나 구동부만 일단 날림
  // await log('detail_screen_view', params);
}

async function logScreenView(type: ScreenViewTypeEnum, value?: string) {
  //TODO - 삭제해도 된다고했으나 구동부만 일단 날림
  // await log('screen_view', {
  //   id: type,
  //   value,
  // });
}

/** 중개플랫폼 신규작업 */
async function logButtonClick(type: ButtonClickType, value?: string) {
  await log('button_click', {
    id: ButtonClickMap[type],
    value,
  });
}

/** 팔아줘 매물등록 퍼널 */
async function logSellingFunnelProceed(
  step: StepKey,
  stepNum: number,
  category: AddSaleItemFunnelData['sellGoodsCategoryType'],
  tradeType: TradeType,
  modify: boolean
) {
  const tradeTypeIdx = tradeType
    ? Object.keys(TradeType).findIndex((type) => type === tradeType)
    : null;
  const meta = {
    id: step,
    stepNum,
    category,
    tradeType: tradeTypeIdx,
    modify,
  };
  await log('selling_funnel_proceed', meta);
}

async function logGetGeolocationCurrentPosition() {
  const { guid } = parseCookie(document.cookie);
  if (!guid) return;
  const ua = new UAParser(navigator.userAgent);
  await log('check_current_position', {
    대상: guid,
    취득경로: ua.getOS().name,
    제공서비스: '주변 부동산 정보',
    제공받는자: '',
  });
}

async function logNaverOpengoodsBrokerCallClick(payload: {
  danjiId: string;
  opengoodsId: number;
  no: number;
  totalNo: number;
}) {
  await log('naver_opengoods_broker_call_click', payload);
}

async function logNaverOpengoodsOutlinkClick(payload: {
  danjiId: string;
  opengoodsId: number;
  no: number;
  totalNo: number;
}) {
  await log('naver_opengoods_outlink_click', payload);
}

async function logNaverOpengoodsViewed(danjiId: string) {
  await log('naver_opengoods_viewed', {
    danjiId,
  });
}

async function logNaverOpengoodsBottomSheetViewed(payload: {
  danjiId: string;
  opengoodsId: number;
  no: number;
  totalNo: number;
}) {
  await log('naver_opengoods_bottomsheet_viewed', payload);
}

async function logMinOfferPriceAnalyticsOpen(payload: {
  danjiId: string;
  from: 'chart' | 'min-offer';
  pyeongType: number;
  tradeType: TradeType;
}) {
  await log('min_offer_price_analytics_open', payload);
}

async function logJoinSelling(payload: {
  type:
    | 'selling_map'
    | 'selling_map_home'
    | 'apt_map_home'
    | 'borker_profile'
    | 'apt_detail_opengoods'
    | 'apt_detail_alarm'
    | 'apt_detail_screen'
    | 'apt_map_mobile'
    | 'opengoods_lowest'
    | 'opengoods_lowest_old_danji'
    | 'my_apt'
    | 'my'
    | 'rebuilding'
    | 'redevelop'
    | 'remodeling';
}) {
  await log('join_selling', payload);
}

type Params<Key extends keyof typeof LogEventCategory> = Key extends keyof LogEventParameters
  ? LogEventParameters[Key]
  : undefined;
type LogEventParameterType<T extends keyof typeof LogEventCategory> = Params<T> extends undefined
  ? [name: T]
  : [name: T, params: Params<T>];

async function logEvent<T extends keyof typeof LogEventCategory>(...args: LogEventParameterType<T>) {
  const [name, meta] = args;
  try {
    await log(name, ...(meta ? [meta] : []));
  } catch (error) {
    console.log('logEvent error', error);
  }
}

const analytics = {
  Enum: {
    screenViewType: ScreenViewTypeEnum,
  },
  log,
  logSessionStart,
  ...{
    // 여기는 정리된 로그만 기록
    getMapMode,
    v2_logGnb, // GNB
    v2_logGnbMobile, // 모바일 GNB
    v2_logMorePage, // 더보기 페이지
    v2_logMorePageDashBoard, // 더보기 페이지
    v2_logToggleMarkerClick, // 마커 토글
    v2_logFilter, // 필터
    v2_logFloating, // 플로팅메뉴
    v2_logMarker, //마커
    v2_logBjdSelect, //지역선택기
    v2_logRankingBoard, //랭킹보드
    v2_logAdMarker, //지도내 광고마커
    v2_logAnalysisMapMode, // 지역분석모드
    v2_logAuctionGnb, // 경매gnb
    v2_logAlarm,
    v2_logSceneView, // 주요 화면뷰
    v2_logAppDown, // 앱다운
    v2_logAiPredict, // AI 미래가격
    v2_logDanjiDetail, // 단지상세 테스트중
    v2_logHome, // 단지상세 테스트중
    v2_logParcelTableView, // 청약상세 테이블
    v2_logHedge, // 헷지했지 테이블
  },
  ...{ logDetailScreenView, logScreenView }, //삭제대상
  logMapWidgetClick,
  logGetGeolocationCurrentPosition,

  ...{
    logNaverOpengoodsBrokerCallClick,
    logNaverOpengoodsOutlinkClick,
    logNaverOpengoodsViewed,
    logNaverOpengoodsBottomSheetViewed,
  },
  logMinOfferPriceAnalyticsOpen,
  logButtonClick,
  logSellingFunnelProceed,
  logJoinSelling,
  logEvent,
};
analytics.logSessionStart();
export default analytics;
