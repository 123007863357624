import { APPLE_CLIENT_ID, RICHGO_API_HOST } from 'coreSrc/core/js/env';

const APPLE_LOGIN_BEFORE_URL_KEY = 'APPLE_LOGIN_BEFORE_URL';

export default class AppleLoginHelper {
  static getAppleLoginUrl() {
    const path = 'https://appleid.apple.com/auth/authorize';
    const urlSearchParams = new URLSearchParams({
      client_id: APPLE_CLIENT_ID,
      redirect_uri: `${RICHGO_API_HOST}/oauth/apple_callback`,
      response_type: 'code',
      response_mode: 'form_post',
      scope: 'email name',
    });

    return path + '?' + urlSearchParams;
  }

  static setBeforeUrl() {
    sessionStorage.setItem(APPLE_LOGIN_BEFORE_URL_KEY, location.href.replace(/#.+$/, ''));
  }

  static getBeforeUrl() {
    return sessionStorage.getItem(APPLE_LOGIN_BEFORE_URL_KEY) ?? location.origin;
  }
}
