import richFetch from 'coreSrc/core/js/utils/richFetch';

import factoryCall from './factoryCall';

function factoryFetchCall(actionType, url, fetchOptions) {
  return factoryCall(
    actionType,
    async (payload) => {
      const requestUrl = typeof url === 'function' ? url(payload) : url;
      const options = typeof fetchOptions === 'function' ? fetchOptions(payload) : fetchOptions;
      const result = await richFetch(requestUrl, options);
      return await result.json();
    },
    (resJson) => resJson.result
  );
}

export default factoryFetchCall;
