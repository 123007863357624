const toFixedWithRoundDown = (num, toFixed) => {
  const base = Math.pow(10, toFixed);
  return `${+(Math.floor(num * base) / base).toFixed(toFixed)}`;
};

const getEtcUnit = (num, multiply) => {
  return `.${num % multiply}`;
};

// 가격 상세
const getMoneyMiddleText = (price) => {
  let result = null;
  // let toFixed = 0;
  if (price !== -1 && price !== null) {
    price = parseInt(price, 10);
    const p10000 = 10000;
    // const p1000 = 1000;
    // const p100 = 100;

    let priceMultiply;
    let baseUnit = '';
    let etcUnit = '';
    let unit = '만';
    let unit2 = '만';
    let unit3 = '만';
    // 억단위

    if (price >= (priceMultiply = p10000 * p10000)) {
      // toFixed = 4;
      baseUnit = price / priceMultiply;
      etcUnit = getEtcUnit(price, priceMultiply);
      unit = '조';
    } else if (price >= (priceMultiply = p10000)) {
      // toFixed = 4;
      baseUnit = price / priceMultiply;
      etcUnit = getEtcUnit(price, priceMultiply);
      unit = '억';
      unit2 = '억';
      unit3 = '';
      // } else if (price >= (priceMultiply = p1000)) {
      //   toFixed = 3;
      //   baseUnit = (price / priceMultiply);
      //   etcUnit = toFixedWithRoundDown(price % priceMultiply / priceMultiply, toFixed);
      //   unit = '천만';
      //   unit2 = '천';
      //   unit3 = '만';
    } else if (price > 0) {
      // toFixed = 0;
      baseUnit = price;
      unit = '만';
    } else {
      // toFixed = 0;
      baseUnit = 0;
      unit = '';
    }
    // console.log(baseUnit, etcUnit, unit);
    baseUnit = Math.floor(baseUnit).toLocaleString();
    const splitResult = etcUnit.split('.');
    if (splitResult.length === 1 || +splitResult[1] === 0) {
      result = baseUnit + unit;
    } else {
      result = `${baseUnit}${unit2} ${(+splitResult.slice(1).join()).toLocaleString()}${unit3}`;
    }
  }
  return {
    value: result,
    format: result === null ? '-' : result,
    raw: price,
  };
};

/**
 * price의 단위는 만원입니다.
 * ex) 1000 = 1000만
 * @param {number} price
 */
function getMoneyDetailText(price) {
  const result = getMoneyMiddleText(price);
  result.format = result.format.replace(/억[ ][0-9,]+$/, (r) => r + '만');
  return result;
}

const PARSER_PRICE = {
  /**
   * 전체적으로 결과는
   * {
   *   value: '파싱된 데이터',
   *   format : '포멧팅된 데이터',
   *   raw: '원래 데이터',
   * };
   */

  /**
   * 은행이자 같이 억미만 정도의 돈텍스트
   * 120.3만원 / 즉 * 10000 안한다.
   */
  getMoneyTextOrigin10000: (price, toFixed = 1) => {
    if (price > 1_0000_0000) {
      return getMoneyMiddleText(price / 10000);
    }
    let result = null;
    let baseUnit = '';
    let etcUnit = '';
    const unit = '만';
    if (price !== -1 && price !== null) {
      baseUnit = price / 10000;
      etcUnit = toFixedWithRoundDown((price % 10000) / 10000, toFixed);
      baseUnit = Math.floor(baseUnit).toLocaleString();
      const splitResult = etcUnit.split('.');
      if (splitResult.length === 1 || +splitResult[1] === 0) {
        result = baseUnit + unit;
      } else {
        result = `${baseUnit}.${(+splitResult.slice(1).join()).toLocaleString()}${unit}`;
      }
    }
    return {
      value: result,
      format: result === null ? '-' : result.toLocaleString(),
      raw: price,
    };
  },
  getMoneyTextAuction: (price, toFixed = 4, useLocalString = false) => {
    let result = null;
    if (price) {
      result = price;
    }
    return {
      format: result === null ? '-' : numberToKorean(price, useLocalString),
    };
  },
  // 가격 약식
  getMoneyText: (price, toFixed = 1) => {
    const isNegative = price < 0;
    price = isNegative ? price * -1 : price;
    let result = null;
    if (price !== -1 && price !== null) {
      const p10000 = 10000;
      const p1000 = 1000;
      const p100 = 100;

      let priceMultiply;
      let baseUnit = '';
      let etcUnit = '';
      let unit = '만';
      // 억단위

      if (price >= (priceMultiply = p10000 * p10000)) {
        baseUnit = price / priceMultiply;
        etcUnit = toFixedWithRoundDown((price % priceMultiply) / priceMultiply, toFixed);
        unit = '조';
      } else if (price >= (priceMultiply = p10000)) {
        baseUnit = price / priceMultiply;
        etcUnit = toFixedWithRoundDown((price % priceMultiply) / priceMultiply, toFixed);
        unit = '억';
      } else if (price >= (priceMultiply = p1000)) {
        baseUnit = price / priceMultiply;
        etcUnit = toFixedWithRoundDown((price % priceMultiply) / priceMultiply, toFixed);
        unit = '천';
      } else if (price >= (priceMultiply = p100)) {
        baseUnit = price / priceMultiply;
        if (parseInt(baseUnit, 10) * priceMultiply === price) {
          unit = '백';
        } else {
          baseUnit = price;
          unit = '만';
        }
      } else {
        baseUnit = price;
        unit = '만';
      }

      baseUnit = Math.floor(baseUnit).toLocaleString();
      const splitResult = etcUnit.split('.');
      // console.log('baseUnit',baseUnit,splitResult)
      if (splitResult.length === 1 || +splitResult[1] === 0) {
        result = baseUnit + unit;
      } else {
        result = `${baseUnit}.${splitResult.slice(1).join().toLocaleString()}${unit}`;
      }
    }
    return {
      value: isNegative ? `-${result}` : result,
      format: result === null ? '-' : isNegative ? `-${result}` : result,
      raw: isNegative ? Number(`-${price}`) : price,
    };
  },
  getMoneyMiddleText,
  getMoneyDetailText,
};
export default PARSER_PRICE;
const numberToKorean = (number, useLocalString) => {
  let inputNumber = number < 0 ? false : number;
  let unitWords = ['', '만', '억', '조', '경'];
  let splitUnit = 10000;
  let splitCount = unitWords.length;
  let resultArray = [];
  let resultString = '';

  for (let i = 0; i < splitCount; i++) {
    let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
    unitResult = Math.floor(unitResult);
    if (unitResult > 0) {
      resultArray[i] = unitResult;
    }
  }

  for (let i = 0; i < resultArray.length; i++) {
    if (!resultArray[i]) continue;
    resultString =
      String(useLocalString ? resultArray[i].toLocaleString() : resultArray[i]) +
      unitWords[i] +
      ' ' +
      resultString;
  }

  return resultString.trim() + '원';
};
