/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type PeriodType = (typeof PeriodType)[keyof typeof PeriodType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PeriodType = {
  ThreeYears: 'ThreeYears',
  FiveYears: 'FiveYears',
  All: 'All',
} as const;
