import SimpleMd from 'hostSrc/comp/SimpleMd';
import styled, { css } from 'styled-components';
import { DialogAction } from '../types';
import isPc from 'hostSrc/_initDatas/isPc';
import useSystem from '../../useSystem';

type DefaultSkinProps = {
  title: string;
  message: string;
  actions?: DialogAction[];
  onClickClose: () => void;
};
function DefaultSkin({ title, message, actions = [], onClickClose, ...props }: DefaultSkinProps) {
  const sys = useSystem();
  const pcYn = isPc(sys);
  const onClickButton = (action) => () => {
    const onClickResult = action.onClick?.();
    if (action.close || !action.onClick || onClickResult) {
      onClickClose();
    }
  };
  return (
    <SD_Container {...props} pcYn={pcYn}>
      <SD_Body>
        {!!title && <SD_Title>{title}</SD_Title>}
        {!!message && <SD_Message text={message} />}
      </SD_Body>
      <SD_Footer>
        {actions
          .filter((it) => !!it)
          .map((action, i) => (
            <SD_Button
              key={i}
              onClick={onClickButton(action)}
              primary={action.primary}
              warning={action.warning}
              danger={action.danger}>
              {action.label}
            </SD_Button>
          ))}
      </SD_Footer>
    </SD_Container>
  );
}

const SD_Container = styled.div<{ pcYn: boolean }>`
  width: ${(p) => (p.pcYn ? 40 : 80)}%;
  max-height: calc(100% - 40px);
  border-radius: 12px;
  background-color: white;
  overflow-y: auto;
`;

const SD_Body = styled.div`
  padding: 30px;
`;
const SD_Title = styled.h4`
  text-align: center;
`;
const SD_Message = styled(SimpleMd)`
  text-align: center;
  color: #505967;
`;

const SD_Footer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1px;
  padding-top: 1px;

  background-color: #e9eaec;
`;
type SD_ButtonProps = {
  primary?: boolean;
  warning?: boolean;
  danger?: boolean;
};
const SD_Button = styled.button<SD_ButtonProps>`
  flex: 1;
  padding: 18px 0;

  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.4px;
  background-color: #fff;

  color: rgba(42, 47, 55, 1);
  ${(p) =>
    p.primary &&
    css`
      color: rgba(59, 130, 246, 1);
    `}
  ${(p) => p.warning && css``}
  ${(p) => p.danger && css``}
`;

export default DefaultSkin;
