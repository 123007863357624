/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

/**
 * 팔아죠 매물 타입
 */
export type SellGoodsCategoryType =
  (typeof SellGoodsCategoryType)[keyof typeof SellGoodsCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SellGoodsCategoryType = {
  APT_ALL: 'APT_ALL',
  OFFICETEL_ALL: 'OFFICETEL_ALL',
  TICKET_APT: 'TICKET_APT',
  TICKET_OFFICETEL: 'TICKET_OFFICETEL',
  HOUSE_ONE_ROOM_OPEN: 'HOUSE_ONE_ROOM_OPEN',
  HOUSE_ONE_ROOM_DIVIDE: 'HOUSE_ONE_ROOM_DIVIDE',
  HOUSE_TWO_ROOM_OR_MORE: 'HOUSE_TWO_ROOM_OR_MORE',
  STORE_ALL: 'STORE_ALL',
  OFFICE: 'OFFICE',
  BUILDING: 'BUILDING',
  LAND: 'LAND',
  ETC: 'ETC',
} as const;
