import mockGetApiSellDetailAptSellDanjiId from 'mocks/handlers/mockGetApiSellDetailAptSellDanjiId';
import mockGetApiSellMyGoodsList from 'mocks/handlers/mockGetApiSellMyGoodsList';

const mockDataList = [
  {
    data: mockGetApiSellDetailAptSellDanjiId,
    switch: 'off',
  },
  {
    data: mockGetApiSellMyGoodsList,
    switch: 'off',
  },
];

export const handlers = mockDataList.filter((item) => item.switch === 'on').map((item) => item.data);
