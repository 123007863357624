const CONST_NAVER_MAP_LEVEL = {
  TARGET_LAND: 16,
  TARGET_MARKER: 15, // 아파트레벨값
  MIN_LEVEL_DANJI: 14, // 아파트레벨값
  MIN_LEVEL_EMD: 13, // 읍면동 레벨값
  MIN_LEVEL_SGG: 9, // 시군구 레벨값

  '10KM': 10,
  '500M': 14,
  '300M': 15,
  '100M': 16,
  '20M': 19,
};

export default CONST_NAVER_MAP_LEVEL;
