export type TNotificationDanjiSettingTopicId = 'meme' | 'jeonwolse' | 'story' | 'goodnews';
export type TNotificationDanjiSettingTopicCode = 'MEME' | 'JEONSE' | 'COMMUNITY' | 'GOODNEWS';

export type TNotificationParcelSettingTopicId = 'schedule' | 'story' | 'goodnews';
export type TNotificationParcelSettingTopicCode = 'SCEHDULE' | 'COMMUNITY' | 'GOODNEWS';

export default class NotificationHelper {
  static toDanjiSettingTopic(
    key: TNotificationDanjiSettingTopicId | TNotificationDanjiSettingTopicCode
  ) {
    if (key === 'meme' || key === 'MEME') {
      return {
        id: 'meme',
        code: 'MEME',
      };
    } else if (key === 'jeonwolse' || key === 'JEONSE') {
      return {
        id: 'jeonwolse',
        code: 'JEONSE',
      };
    } else if (key === 'goodnews' || key === 'GOODNEWS') {
      return {
        id: 'goodnews',
        code: 'GOODNEWS',
      };
    } else if (key === 'story' || key === 'COMMUNITY') {
      return {
        id: 'story',
        code: 'COMMUNITY',
      };
    }

    throw new Error('존재하지 않는 단지 알림 토픽 키입니다');
  }

  static toParcelSettingTopic(
    key: TNotificationParcelSettingTopicId | TNotificationParcelSettingTopicCode
  ) {
    if (key === 'schedule' || key === 'SCEHDULE') {
      return {
        id: 'schedule',
        code: 'SCEHDULE',
      };
    } else if (key === 'goodnews' || key === 'GOODNEWS') {
      return {
        id: 'goodnews',
        code: 'GOODNEWS',
      };
    } else if (key === 'story' || key === 'COMMUNITY') {
      return {
        id: 'story',
        code: 'COMMUNITY',
      };
    }

    throw new Error('존재하지 않는 단지 알림 토픽 키입니다');
  }
}
