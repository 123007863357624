import t from './actionTypes';

const initialState = {
  detail: {},
};

function regionDetailReducer(state = initialState, action) {
  switch (action.type) {
    case t.SUCCESS_GET_REGION_DETAIL:
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.requestParams.bjdCode]: action.payload,
        },
      };
    case t.UPDATE_COMPARED_REGIONS: {
      return {
        ...state,
        detail: {
          ...state.detail,
          [action.payload.bjdCode]: {
            ...state.detail[action.payload.bjdCode],
            comparedRegions: action.payload.comparedRegions,
          },
        },
      };
    }
  }
  return state;
}

export default regionDetailReducer;
