/**
 * brokerApprovalState
 * ```
 * public enum BrokerApprovalState
 * {
 *     WAIT     = 0, // 대기
 *     APPROVAL = 1, // 승인
 *     DENY     = 2, // 거부
 *     DELETE   = 3, // 삭제
 *     WITHDRAW = 4, // 회원 탈퇴
 *     NONE     = 99 // 아무것도 아님
 * }
 * ```
 */
export type BrokerApprovalState = 0 | 1 | 2 | 3 | 4 | 99;

export default class UserProfile {
  id = '';
  name = '';
  email = '';
  externalEmail = '';
  hasLatestAgreedTerm = false;
  livingArea = {
    sido: '',
    sigungu: '',
    eupMyunDong: '',
  };
  community: {
    userId: '';
    stayYear: 0;
    jobName: 99;
    updateDtm: '';
  } | null = null;
  myHouseYn = false;
  myParcelConditionYn = false;
  brokerIsAgreed = false;
  agencyApprovalStateType: 99;
  brokerApprovalState: BrokerApprovalState;
  agencyId: null;
  favoriteCount = 0;
  isKakaoPhoneAgree = false;
  externalProviderName: '' | 'apple' | 'kakao' = '';
  isMarketingAgreed = false;

  constructor(profileFromServer?: any) {
    // console.log('profileFromServer', profileFromServer);

    if (profileFromServer) {
      this.id = profileFromServer.id ?? '';
      this.name = profileFromServer.name ?? '';
      this.email = profileFromServer.email ?? '';
      this.externalEmail = profileFromServer.externalEmail ?? '';
      this.livingArea = {
        sido: profileFromServer?.sido ?? '',
        sigungu: profileFromServer?.sigungu ?? '',
        eupMyunDong: profileFromServer?.eupMyunDong ?? '',
      };
      if (profileFromServer.community !== null) {
        this.hasLatestAgreedTerm = (profileFromServer.termsList ?? [])
          .filter(
            (term) =>
              // term.name === 'info_collecting' ||
              // term.name === 'info_processing' ||
              // term.name === '14_over' ||
              // term.name === 'location' ||
              term.name === 'richgoV2'
          )
          .every((term) => term.isAgreed === true);
        this.community = {
          userId: profileFromServer.community?.userId ?? '',
          stayYear: profileFromServer.community?.stayYear ?? 0,
          jobName: profileFromServer.community?.jobName ?? 99,
          updateDtm: profileFromServer.community?.updateDtm ?? '',
        };
      }
      this.myHouseYn = profileFromServer.myHouseYn;
      this.myParcelConditionYn = profileFromServer.myParcelConditionYn;
      this.brokerIsAgreed = (profileFromServer.termsList ?? []).find(
        (term) => term.name === 'broker' && term.isAgreed
      )
        ? true
        : false;
      this.brokerApprovalState = profileFromServer.brokerApprovalState;
      this.agencyApprovalStateType = profileFromServer.agencyApprovalStateType;
      this.agencyId = profileFromServer.agencyId;
      this.favoriteCount = profileFromServer.favoriteCount ?? 0;
      this.isKakaoPhoneAgree = profileFromServer.isKakaoPhoneAgree ?? false;
      this.externalProviderName = profileFromServer.externalProviderName ?? '';
      this.isMarketingAgreed = (profileFromServer.termsList ?? []).find(
        (term) => term.name === 'basic_marketing' && term.isAgreed
      )
        ? true
        : false;
    }
  }
}
