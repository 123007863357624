import type { AppContext } from 'next/app';

import { parseCookie } from 'coreSrc/core/js/utils/cookie';

import {
  RICHGO_API_ACCESS_TOKEN_KEY,
  RICHGO_API_REFRESH_TOKEN_KEY,
} from 'coreSrc/base/userSystemManager/user/authManager/const';

export default class ContextRichgoAuthHelper {
  static setRefreshToken(appContext: AppContext, token: string) {
    if (!appContext) return;
    appContext[RICHGO_API_REFRESH_TOKEN_KEY] = token;
  }

  static getRefreshToken(appContext?: AppContext) {
    if (appContext) return appContext[RICHGO_API_REFRESH_TOKEN_KEY];
    return parseCookie(document.cookie)[RICHGO_API_REFRESH_TOKEN_KEY];
  }

  static setAccessToken(appContext: AppContext, token: string) {
    if (!appContext) return;
    appContext[RICHGO_API_ACCESS_TOKEN_KEY] = token;
  }

  static getAccessToken(appContext?: AppContext) {
    if (appContext) return appContext[RICHGO_API_ACCESS_TOKEN_KEY];
    return parseCookie(document.cookie)[RICHGO_API_ACCESS_TOKEN_KEY];
  }
}
