import styled, { css } from 'styled-components';

interface ITextProps {
  className?: string;
  width?: string;
  inline?: boolean;
  lineClamp?: 1 | 2 | 3;
  padding?: string;
  color?: string;
  background?: string;
  decoration?: 'overline' | 'underline' | 'none' | 'line-through';
  whiteSpace?: 'nowrap' | 'pre' | 'pre-line' | 'pre-wrap';
  wordBreak?: 'break-all' | 'keep-all' | 'normal';
  spacing?: string;
  lineHeight?: string;
  size?: string;
  weight?:
    | '100'
    | '200'
    | '300'
    | '400'
    | '500'
    | '600'
    | '700'
    | '800'
    | '900'
    | 'normal'
    | 'bold'
    | 'lighter'
    | 'bolder';
  onClick?: () => void;
}

let Text: React.FunctionComponent<ITextProps> = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      {props.children}
    </div>
  );
};

Text = styled(Text)`
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};

  width: ${({ width }) => width ?? 'auto'};

  padding: ${({ padding }) => padding || 0};

  color: ${({ color }) => color || '#2A2F37'};
  background: ${({ background }) => background || 'transparent'};

  text-decoration: ${({ decoration }) => decoration || 'none'};

  word-break: ${({ wordBreak }) => wordBreak || 'break-all'};
  white-space: ${({ wordBreak, whiteSpace }) => (wordBreak ? 'pre-wrap' : whiteSpace || 'pre')};

  letter-spacing: ${({ spacing }) => spacing || '-0.6px'};
  line-height: ${({ lineHeight }) => lineHeight || '24px'};
  font-size: ${({ size }) => size || '14px'};
  font-weight: ${({ weight }) => weight || 'normal'};

  ${({ lineClamp }) => (lineClamp === 1 ? lineClamp1 : '')}
  ${({ lineClamp }) => (lineClamp === 2 ? lineClamp2 : '')}
  ${({ lineClamp }) => (lineClamp === 3 ? lineClamp3 : '')}
`;

const lineClamp1 = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const lineClamp2 = css`
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  white-space: pre-wrap;
`;

const lineClamp3 = css`
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  white-space: pre-wrap;
`;

export default Text;
