/* NOTE: kunhee.lim
 *  ['a', 'b'].includes(c) 같은 코드를 사용할때 타입 유실되지 않도록 쓰는 함수
 *  첫번째 파라미터는 as const 로 사용할 것 */
function isIncluded<T, K extends T>(arr: readonly K[], item: T): item is K {
  /* NOTE: kunhee.lim
   *  item as K를 한 이유는 includes 의 타입에러때문(as any를 해도 됨)
   *  includes 에 들어갈 파라미터 타입이 arr 타입에 한정되도록 타입이 정의 되어있기 때문 */
  return arr.includes(item as K);
}

export default isIncluded;
