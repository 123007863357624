import { TradeType } from 'hostSrc/apis/model';

const ConstSellingAdmin = {
  SellDanjiStateType: {
    WRITE_COMPLETED: { code: 1, label: '작성 중' },
    UNDER_REVIEW: { code: 2, label: '판매 중', label2: '미인증' }, // 심사중에서 표기만 판매중으로 변경됨
    SALE: { code: 3, label: '판매 중' },
    COMPLETE: { code: 4, label: '거래성공' },
    DELETE_ON_SALE: { code: 5, label: '판매 중 삭제' },
    DELETE_BEFORE_SALE: { code: 6, label: '판매 전 삭제' },
    DENIAL: { code: 7, label: '심사탈락' },
    // ALL: {'code': 99, label: 'ALL 실제론안씀'},
  },

  /* NOTE: kunhee.lim
   *  대화하기 관련 쓰이는 SellDanjiStateType -> SellGoodsStageType 로 교체  */
  SellGoodsStageType: {
    WRITE_COMPLETED: { label: '판매 중' },
    SALE: { label: '판매 중' },
    COMPLETE: { label: '거래성공' },
    DELETE_ON_SALE: { label: '삭제' },
    DELETE_BEFORE_SALE: { label: '삭제' }, // -> 없어질 예정
    DENIAL: { label: '심사탈락' }, // -> 없어질 예정
  },

  AgencyApprovalState: {
    WAIT: { code: 0, label: '심사' },
    APPROVAL: { code: 1, label: '승인' },
    DENY: { code: 2, label: '거부' },
    DELETE: { code: 3, label: '삭제' },
  },
  BrokerApprovalState: {
    WAIT: { code: 0, label: '심사' },
    APPROVAL: { code: 1, label: '승인' },
    DENY: { code: 2, label: '거부' },
    DELETE: { code: 3, label: '삭제' },
    WITHDRAW: { code: 4, label: '탈퇴' },
    NONE: { code: 99, label: '아무것도 아님' },
  },
  WishDateType: {
    NOT_SELECT: { code: 0, label: '희망날짜' },
    ASAP: { code: 1, label: '최대한빨리' },
    WHENEVER: { code: 2, label: '아무떄나' },
  },
  SellType: {
    APT: { code: 0, label: '아파트', iconName: 'selling_addIntro_sellType_APT' },
    OFFICETEL: {
      code: 2,
      label: '오피스텔',
      disable: true,
      iconName: 'selling_addIntro_sellType_OFFICE',
    },
    APT_TICKET: {
      code: 1,
      label: '아파트 분양권',
      disable: true,
      iconName: 'selling_addIntro_sellType_APTS',
    },
    OFFICETEL_TICKET: {
      code: 3,
      label: '오프스텔 분양권',
      disable: true,
      iconName: 'selling_addIntro_sellType_OFFICES',
    },
  },
  WishType: {
    MEME: { code: 0, label: '매매', label2: '아파트 팔기', iconName: 'selling_wishType_meme' },
    JEONSE: { code: 1, label: '전세', label2: '전세 내놓기', iconName: 'selling_wishType_jeonse' },
    MONTHLY: { code: 2, label: '월세', label2: '월세 내놓기', iconName: 'selling_wishType_month' },
    SHORT_MONTHLY: {
      code: 3,
      label: '단기임대',
      label2: '단기임대 내놓기',
      iconName: 'selling_wishType_shortMonth',
    },
  },

  /* NOTE: kunhee.lim
   *  대화하기 관련 쓰이는 WishType -> TradeType 로 교체  */
  TradeType: {
    Meme: { code: 0, label: '매매', label2: '아파트 팔기', iconName: 'selling_wishType_meme' },
    Jeonse: { code: 1, label: '전세', label2: '전세 내놓기', iconName: 'selling_wishType_jeonse' },
    Rent: { code: 2, label: '월세', label2: '월세 내놓기', iconName: 'selling_wishType_month' },
    ShortTermRental: {
      code: 3,
      label: '단기임대',
      label2: '단기임대 내놓기',
      iconName: 'selling_wishType_shortMonth',
    },
  },

  RentType: {
    NOT_RENT: { code: 0, label: '매매' },
    JEONSE: { code: 1, label: '전세' },
    MONTHLY: { code: 2, label: '월세' },
    SHORT_MONTHLY: { code: 3, label: '단기임대' },
  },
  RegisterUserType: {
    OWNER: { code: 0, label: '본인' },
    RENTER: { code: 1, label: '임차인(세입자)' },
    FAMILY: { code: 2, label: '가족' },
    BROKER: { code: 3, label: '중개인' },
  },
  ///
  SellInteriorType: {
    None: { code: 0, label: '인터리어 안함' },
    ALL: { code: 1, label: '전체' },
    PART: { code: 2, label: '부분' },
  },
  SellInteriorTimeType: {
    None: { code: 0, label: 'None' },
    MONTH_3: { code: 1, label: '3개월이내' },
    MONTH_6: { code: 2, label: '6개월이내' },
    YEAR_1: { code: 3, label: '1년이내' },
    YEAR_2: { code: 4, label: '2년이내' },
    YEAR_3: { code: 5, label: '3년이내' },
    YEAR_4: { code: 6, label: '4년이내' },
    YEAR5: { code: 7, label: '5년이내' },
  },
  IdentityVerificationYnEnum: {
    NO: { code: 0, label: '미인증' },
    YES: { code: 1, label: '인증' },
    UNDER_REVIEW: { code: 2, label: '심사중' },
  },
  InternalFacilitiesType: {
    ELEVATOR: { code: 0, label: '엘리베이터' },
    MANAGEMENT: { code: 1, label: '관리실' },
    READING_ROOM: { code: 2, label: '독서실' },
    DAY_CARE_CENTER: { code: 3, label: '어린이집' },
  },
  SellEstimateStateType: {
    REQUEST: { code: 0, label: '중개제안' },
    APPROVAL: { code: 1, label: '매칭완료' },
    DENY: { code: 2, label: '견적거절' },
    COMPLETED: { code: 3, label: '거래성공' },
    FAIL: { code: 4, label: '타인거래' },
    FAIL_REQUEST: { code: 5, label: '거래실패' },
  },
  TalkType: {
    TALK: { code: 0, label: '일반대화' },
    ESTIMATE: { code: 1, label: '중개 제안서' },
    REQUEST: { code: 2, label: '고객요청' },
  },
  TalkerType: {
    USER: { code: 0, label: '매도자' },
    BROKER: { code: 1, label: '브로커' },
    SYSTEM_SELL: { code: 2, label: '매물 관련 시스템 대화 ' },
    SYSTEM_ESTIMATE: { code: 3, label: '중개 제안서  관련 시스템 대화' },
    SYSTEM_REQUEST: { code: 4, label: '요청   관련 시스템 대화' },
  },
  SystemTalkType: {
    ESTIMATE_APPROVAL: { code: 0, label: '중개 제안서 승인' },
    ESTIMATE_DENY: { code: 1, label: '중개 제안서 거부' },
    COMPLETE: { code: 2, label: '판매완료' },
    REQUEST_APPROVAL: { code: 3, label: '신규 요청 ' },
  },

  SellEstimateDeniedType: {
    LOW_PRICE: { code: 0, label: '낮은가격' },
    HIGH_FEE: { code: 1, label: '높은 수수료' },
    OTHER_BROKERS: { code: 2, label: '다른 중개사' },
    PERSONAL_ISSUES: { code: 3, label: '개인사정' },
  },

  SellBrokerListType: {
    TRADING: { code: 0, label: '거래' },
    TALK: { code: 1, label: '대화' },
    FAVORITE: { code: 2, label: '관심' },
    REQUEST: { code: 3, label: '의뢰' },
  },
  SellRequestStateType: {
    REQUEST: { code: 0, label: '중개요청' },
    APPROVAL: { code: 1, label: '중개수락' },
    DENIAL: { code: 2, label: '중개거절' },
  },
  RequestRefuseType: {
    HIGH_PRICE: { code: 0, label: '높은가격' },
    QUICK_MOVE_IN: { code: 1, label: '빠른 입주 ' },
    PERSONAL_ISSUES: { code: 2, label: '개인사정' },
    DIRECT_INPUT: { code: 3, label: '직접입력' },
  },

  SellDanjiOrder: {
    CREATE_DTM: { code: 0, label: '등록일 최신순' },
    MOVE_IN_DATE: { code: 1, label: '입주가능일 느린순' },
    CREATE_DTM_DESC: { code: 2, label: '등록일  빠른순' },
    MOVE_IN_DATE_DESC: { code: 3, label: '입주가능일 빠른순 ' },
  },

  SellBrokerType: {
    EMPLOYEE: { code: 0, label: '소속 중개사' },
    CEO: { code: 1, label: '대표 중개사' },
    HELPER: { code: 2, label: '중개보조원' },
  },
  WishSellState: {
    NO: { code: 0, label: 'NO' },
    EMPTY_BROKER: { code: 1, label: 'EMPTY_BROKER' },
    NEW_BROKER: { code: 2, label: 'NEW_BROKER' },
  },

  RadarTransactionPurposeType: {
    NONE: { code: 0, label: '미선택' },
    INVEST: { code: 1, label: '투자용' },
    ACTUAL_RESIDENCE: { code: 2, label: '실거주용' },
  },

  RadarThinkingBuyType: {
    NONE: { code: 0, label: '미선택' },
    SOON: { code: 1, label: '조건만 맞으면 바로' },
    IN_6_MONTH: { code: 2, label: '6개월 이내' },
    IN_1_YEAR: { code: 3, label: '1년 이내' },
    RECOMMEND_ONLY: { code: 4, label: '추천만 받을께요' },
  },

  getByIndexList: (target) => {
    const t0 = {};
    const targetDatas = ConstSellingAdmin[target];
    for (const key in targetDatas) {
      const tData = targetDatas[key];
      t0[tData['code']] = tData;
    }

    return t0;
  },
  getLabel: (target, code) => {
    if (typeof code === 'string') {
      return ConstSellingAdmin?.[target]?.[code]?.label ?? '-';
    }

    const t0 = ConstSellingAdmin.getByIndexList(target)[code];
    return t0 ? t0['label'] : '-';
  },

  deleteSalItemStateYn: (sellDanjiState) => {
    const { SellDanjiStateType } = ConstSellingAdmin;
    return (
      sellDanjiState === SellDanjiStateType.DELETE_BEFORE_SALE.code ||
      sellDanjiState === SellDanjiStateType.DELETE_ON_SALE.code
    );
  },
  disableModifyStateYn: (sellDanjiState) => {
    const { SellDanjiStateType } = ConstSellingAdmin;
    return (
      sellDanjiState === SellDanjiStateType.DELETE_BEFORE_SALE.code ||
      sellDanjiState === SellDanjiStateType.DELETE_ON_SALE.code ||
      sellDanjiState === SellDanjiStateType.COMPLETE.code ||
      sellDanjiState === SellDanjiStateType.DENIAL.code
    );
  },
  talkAbleStateYn: (sellDanjiState, talkBrokerList, brokerYn) => {
    return (
      (sellDanjiState === ConstSellingAdmin.SellDanjiStateType.UNDER_REVIEW.code || // 심사중도 추가됨
        sellDanjiState === ConstSellingAdmin.SellDanjiStateType.SALE.code ||
        sellDanjiState === ConstSellingAdmin.SellDanjiStateType.COMPLETE.code) &&
      (!!talkBrokerList.length || brokerYn)
    );
  },
  modifyAbleState: (sellDanjiState) => {
    const { SellDanjiStateType } = ConstSellingAdmin;
    return sellDanjiState === SellDanjiStateType.WRITE_COMPLETED.code
      ? 'ALL'
      : sellDanjiState === SellDanjiStateType.UNDER_REVIEW.code ||
        sellDanjiState === SellDanjiStateType.SALE.code
      ? 'PART'
      : 'DISABLE';
  },
  deleteAbleStateYn: (sellDanjiState) => {
    const { SellDanjiStateType } = ConstSellingAdmin;
    return (
      sellDanjiState === SellDanjiStateType.WRITE_COMPLETED.code ||
      sellDanjiState === SellDanjiStateType.SALE.code ||
      sellDanjiState === SellDanjiStateType.UNDER_REVIEW.code
    );
  },
  userRequestAbleStateYn: (lastEstimateState, lastRequestState) => {
    return (
      ((lastEstimateState === null ||
        lastEstimateState === ConstSellingAdmin.SellEstimateStateType.DENY.code) &&
        (lastRequestState === null ||
          lastRequestState === ConstSellingAdmin.SellRequestStateType.DENIAL.code)) ||
      (lastEstimateState === ConstSellingAdmin.SellEstimateStateType.DENY.code &&
        lastRequestState === ConstSellingAdmin.SellRequestStateType.APPROVAL.code)
    );
  },
  estimateAbleStateYn: (sellDanjiState, brokerYn, lastEstimateState, lastRequestState) => {
    return (
      (sellDanjiState === ConstSellingAdmin.SellDanjiStateType.UNDER_REVIEW.code || // 심사중도 권한 추가됨
        sellDanjiState === ConstSellingAdmin.SellDanjiStateType.SALE.code) &&
      brokerYn &&
      (lastEstimateState === null ||
        lastEstimateState === ConstSellingAdmin.SellEstimateStateType.DENY.code) &&
      (lastRequestState === null ||
        lastRequestState === ConstSellingAdmin.SellRequestStateType.DENIAL.code)
    );
  },
  monthlyWishTypeYn: (wishType) => {
    return (
      wishType === ConstSellingAdmin.WishType.MONTHLY.code ||
      wishType === ConstSellingAdmin.WishType.SHORT_MONTHLY.code
    );
  },
  monthlyTradeTypeYn: (wishType) => {
    return wishType === TradeType.Rent || wishType === TradeType.ShortTermRental;
  },
};
for (const k in ConstSellingAdmin) {
  const t0 = ConstSellingAdmin[k];
  for (const k2 in t0) {
    const t1 = t0[k2];
    if (!(t1 instanceof Function)) t1['key'] = k2;
  }
}
export default ConstSellingAdmin;
