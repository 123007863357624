/* eslint-disable react/display-name */
import React, { CSSProperties, useEffect, useState } from 'react';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { getUserUserIp } from 'hostSrc/apis/endpoints/user/user';

import { AdLocation } from 'pages/api/ads';

import AnimationContainer from './AnimationContainer';

import styled from 'styled-components';

type Props = {
  location: AdLocation;
  withRadius?: boolean;
  sizeOffset?: number;
  fullWidth?: boolean;
  subData?: any; // 로그용
  adData?: AdData;
  containerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  onSuccess?: () => void;
  onError?: () => void;
};

interface AdData {
  ad_id: string;
  campaign_id: string;
  height: number;
  image_url: string;
  impression_url: string;
  landing_url: string;
  logo_image_url: string;
  logo_landing_url: string;
  width: number;
}

const AdBannerWithApi = React.memo(
  ({
    location,
    withRadius,
    sizeOffset = 1,
    fullWidth,
    subData,
    adData,
    containerStyle,
    imageStyle,
    onSuccess,
    onError,
  }: Props) => {
    const system = useSystem();

    const isMobile = system.detector.isMobile;
    const isIOS = system.detector.isIOS;
    const deviceType = isMobile ? (isIOS ? 2 : 1) : 3;
    const [imageData, setImageData] = useState<AdData>(adData || null);

    useEffect(() => {
      if (adData) return;

      let mounted = true;
      const deviceUuid = sessionStorage.getItem('deviceUuid');

      const getAdsData = async () => {
        const ipAddress = await getUserUserIp().then((res) => res.result.userIP);

        const res = await fetch(
          `/api/ads?location=${location}&deviceType=${deviceType}&deviceId=${
            deviceUuid || ''
          }&ipAddress=${ipAddress || ''}`
        );

        if (res.status === 500) {
          onError?.();
          return;
        }

        const json = await res.json();
        if (json?.exist === 'false') return;

        const data = json.ad[0] as AdData;
        if (mounted) setImageData(data);

        onSuccess?.();
      };

      getAdsData();

      return () => {
        mounted = false;
      };
    }, []);

    if (!imageData) return <></>;

    return (
      <SD_Link
        href={imageData.logo_landing_url}
        onClick={(e) => {
          e.preventDefault();
          system.router.goOut(imageData.landing_url, true);
        }}
        style={containerStyle}
      >
        <SD_Image
          src={imageData.image_url}
          width={imageData.width * sizeOffset}
          // height={imageData.height * sizeOffset}
          alt={`ads-${location}`}
          withRadius={withRadius}
          fullWidth={fullWidth}
          // loading="lazy"
          style={imageStyle}
        />
        <img
          // loading="lazy"
          src={imageData.impression_url}
          width={0}
          height={0}
        />
      </SD_Link>
    );
  }
);

interface NormalBannerType {
  Wrapper: typeof AdBannerNormalWrapper;
}
interface BottomBannerType {
  handleScroll: (scrollTop: number) => void;
  showBanner: boolean;
  Wrapper: typeof AdBannerBottomWrapper;
}

interface DrawerBannerType {
  Drawer: typeof Drawer;
}

interface ModalBannerType {
  Modal: typeof Modal;
  Button: typeof Button;
}

type BannerType = 'normal' | 'bottom' | 'drawer' | 'modal';

type AdBannerProps<T extends BannerType> = {
  type: T;
};

type AdBannerReturnType<T> = {
  AdBannerWithApi: typeof AdBannerWithApi;
  fetchAdBanner: typeof fetchAdBanner;
} & (T extends 'normal'
  ? NormalBannerType
  : T extends 'bottom'
  ? BottomBannerType
  : T extends 'drawer'
  ? DrawerBannerType
  : T extends 'modal'
  ? ModalBannerType
  : never);

const fetchAdBanner = async (location: AdLocation, deviceType: number) => {
  const deviceUuid = sessionStorage.getItem('deviceUuid');
  const ipAddress = await getUserUserIp().then((res) => res.result.userIP);

  const res = await fetch(
    `/api/ads?location=${location}&deviceType=${deviceType}&deviceId=${deviceUuid || ''}&ipAddress=${
      ipAddress || ''
    }`
  );
  // const res = await fetch(`/api/ads?location=${location}&deviceType=${deviceType}`);
  if (res.status === 500)
    return {
      data: null,
      isError: true,
    };

  const json = await res.json();
  if (json?.exist === 'false')
    return {
      data: null,
      isError: true,
    };

  const data = json.ad[0] as AdData;

  return {
    data,
    isError: !data,
  };
};

function useAdBanner<T extends BannerType>({ type }: AdBannerProps<T>): AdBannerReturnType<T> {
  // 여기서 패치해서 데이터 던져주기
  if (type === 'normal') {
    return {
      AdBannerWithApi,
      Wrapper: AdBannerNormalWrapper,
    } as any;
  }

  if (type === 'bottom') {
    const [showBanner, setShowBanner] = useState(true);

    const handleScroll = (scrollTop: number) => {
      if (scrollTop > 50) {
        setShowBanner(false);
      } else {
        setShowBanner(true);
      }
    };

    return {
      showBanner,
      handleScroll,
      Wrapper: AdBannerBottomWrapper,
      AdBannerWithApi,
    } as any;
  }

  if (type === 'drawer') {
    return {
      Drawer,
      AdBannerWithApi,
    } as any;
  }

  if (type === 'modal') {
    return {
      Modal,
      Button,
      fetchAdBanner,
      AdBannerWithApi,
    } as any;
  }
}

const AdBannerNormalWrapper = styled.div`
  background-color: #fff;
  padding-top: 10px;
`;

const SD_Link = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SD_Image = styled.img<{ withRadius?: boolean; fullWidth?: boolean }>`
  //object-fit: cover;
  border-radius: ${(p) => p.withRadius && '10px'};

  width: ${(p) => p.fullWidth && '100%'};
  //height: ${(p) => p.fullWidth && '100%'};
`;

const AdBannerBottomWrapper = styled.div<{ showBanner: boolean; hasGNB?: boolean }>`
  position: sticky;
  width: 100%;
  left: 0;
  bottom: ${(p) => (p.hasGNB ? 52 : 0)}px;
  opacity: ${(p) => (p.showBanner ? 1 : 0)};
  pointer-events: ${(p) => !p.showBanner && 'none'};
  transition: opacity 0.3s ease-in;
  /* background-color: #fff; */
`;

function Drawer({ children, onClickClose = null }) {
  const onClose = () => {
    sessionStorage.setItem('adDrawerYn', 'true');
    onClickClose();
  };

  return (
    <DrawerContainer>
      <DrawerContent>{children}</DrawerContent>
      <DrawerCloseButton onClick={onClose}>닫기</DrawerCloseButton>
    </DrawerContainer>
  );
}

const DrawerContainer = styled(AnimationContainer.PopupContent)`
  background: #fff;
  border-radius: 15px 15px 0px 0px;

  max-height: calc(100% - 80px);
  overflow-y: auto;
`;

const DrawerContent = styled.div`
  min-height: 300px;
`;

const DrawerCloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  position: relative;
  bottom: 0;
`;

const Modal = styled.div`
  min-width: 300px;
  min-height: 200px;
  background: #fff;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  position: relative;
  bottom: 0;
`;

export default AdBannerWithApi;
export { useAdBanner };
