import { ParcelResultDto, SearchAuctionItemDto } from 'hostSrc/apis/model';

import type { IBjdInfo } from 'jhSrc/types/bjd';

export interface UserFavoriteDanjiResponseData {
  danjiId: string;
  danji: string;
  pyeongType: number;
  bjdCode: string;
  bjdInfoDto: IBjdInfo;
  predictInfoDto: {
    bjdCode: string;
    score: number;
    rank: number;
    changeRate1m: number;
    changeRate6m: number;
    changeRate12m: number;
    pricePredictRate: number;
  };
  tradeTypeCode: null;
  avgPrice: number;
  avgPrice3mAgo: number;
  jeonseAvgPrice: number;
  jeonseAvgPrice3mAgo: number;
  location: { x: number; y: number };
}

export interface UserFavoriteDanji {
  id: string;
  name: string;
  pyeong: number;
  bjdInfo: {
    code: string;
    sd: string;
    sgg: string;
    emd: string;
  };
  location: {
    x: number;
    y: number;
  };
  avgPrice: number;
  avgPrice3mAgo: number;
  jeonseAvgPrice: number;
  jeonseAvgPrice3mAgo: number;
}

export default class UserFavorites {
  danjiList = [] as UserFavoriteDanji[];
  officetelList = [] as UserFavoriteDanji[];
  auctionList = [] as SearchAuctionItemDto[];
  parcelList = [] as ParcelResultDto[];

  constructor(profileFromServer?: any) {
    if (profileFromServer) {
      this.danjiList = profileFromServer.danjiList.map(this.mapper);
      this.officetelList = profileFromServer.officetelList.map(this.mapper);
      this.auctionList = profileFromServer.auctionList;
      this.parcelList = profileFromServer.parcelList;
    }
  }

  private mapper(v: UserFavoriteDanjiResponseData) {
    return {
      id: v.danjiId ?? '',
      name: v.danji ?? '',
      pyeong: v.pyeongType,
      bjdInfo: {
        code: v.bjdInfoDto.bjdCode ?? '',
        sd: v.bjdInfoDto.sd ?? '',
        sgg: v.bjdInfoDto.sgg ?? '',
        emd: v.bjdInfoDto.emd ?? '',
      },
      location: {
        x: v.location.x ?? NaN,
        y: v.location.y ?? NaN,
      },
      avgPrice: v.avgPrice,
      avgPrice3mAgo: v.avgPrice3mAgo,
      jeonseAvgPrice: v.jeonseAvgPrice,
      jeonseAvgPrice3mAgo: v.jeonseAvgPrice3mAgo,
    };
  }

  static TYPE_LIST = ['danji', 'officetel', 'auction', 'parcel'];
}
