import t from './actionTypes';
import ConstSellingAdmin from '../../pages/realty/selling/helper/ConstSellingAdmin';
import sellingParsePrice from '../../pages/realty/selling/helper/sellingParsePrice';
import richLocalStorage from 'coreSrc/core/js/utils/richLocalStorage';

const initialState = {
  map: null,
  menuIDX: 0,
  pcMenuIDX: 4,
  cacheMainMarker: {}, // 지도 마커관리용
  cacheMainMarkerList: {}, // 지도 마커관리용
  lastMapData: null,
  uiViewYn: true,
  mapModeYn: false,
  useGeolocation: true,
  currentRegionData: {},
  isInitialized: false,
  markerSetting: {
    iconMode: 0,
    danji: {
      item1: {
        getItem: (v) => {
          const targetData = Object.values(v['sellDanjiDetailList'])[0];
          return `${v['danjiName']} ${targetData['pyeong']}평`;
        },
      },
      item2: {
        getItem: (v) => {
          const targetData = Object.values(v['sellDanjiDetailList'])[0];
          console.log('targetData', targetData);
          return ConstSellingAdmin.getLabel('WishType', targetData['wishType']).charAt(0);
        },
      },
      item3: {
        getItem: (v) => {
          const targetData = Object.values(v['sellDanjiDetailList'])[0];
          const { wishPrice, wishMonthlyPrice, wishType } = targetData;
          return `${sellingParsePrice(wishPrice)} ${
            ConstSellingAdmin.monthlyWishTypeYn(wishType)
              ? `/ ${sellingParsePrice(wishMonthlyPrice)}`
              : ''
          }`;
        },
      },
    },
    region: {
      item1: {
        getItem: (v, key) => {
          return v['bjdInfoDto'][key.toLowerCase()];
        },
      },
      item2: {
        getItem: () => {
          return '2test2';
        },
      },
      item3: {
        getItem: () => {
          return '2test3';
        },
      },
    },
  },
  ui: {
    rt: {
      allMarker: { title: '전체', value: true },
      memeMarker: { title: '매매', value: false },
      jeonseMarker: { title: '전세', value: false },
      monthMarker: { title: '월세', value: false },
      shortMonthMarker: { title: '단기', value: false },
    },
    lt: {
      useGeolocation: false, // 지오로케이션 쓸껀지
      dimentionUtils: {
        distance: {
          title: '거리',
          use: false,
        },
        area: {
          title: '면적',
          use: false,
        },
        // radius: {
        //   title: '반경',
        //   use: false,
        // },
      }, // 거리 면적등
      mapStyle: {
        CadastralLayer: {
          title: '지적도',
          type: 'layer',
          use: false,
          icon: 'pc_map_style_c',
        },
        hybrid: {
          title: '위성',
          use: false,
          icon: 'pc_map_style_s',
        },
        StreetLayer: {
          title: '로드뷰',
          type: 'layer',
          use: false,
        },
        FlightSpot: {
          title: '항공뷰',
          type: 'layer',
          use: false,
        },
      }, // 지도 스타일 오버레이
    },
  },
  ///////////////////////////////
  userItemList: [],
  brokerItemInfo: {
    managementDataList: [],
    talkDataList: [],
    favoriteDataList: [],
  },
  allItemList: [],
  /////

  scenarioTargetData: {
    searchData: null,
    scenarioData: null,
    tradeInfo: null,
  },
  scenarioSellType: null,
  //
  scenarioTargetDongInfo: null,
  scenarioTargetHoInfo: null,
  scenarioTargetPyeongInfo: null,
  scenarioTargetPyeongTypeInfo: null,
  scenarioTargetFloor: null,
  //
  scenarioTargetWishType: null,
  scenarioTargetWishDate: null,
  scenarioTargetWishDateType: null,
  scenarioTargetWishPrice: null,
  scenarioTargetWishMonthlyPrice: null,
  //
  scenarioTargetRentType: null,
  scenarioTargetRentPrice: null,
  scenarioTargetRentMonthlyPrice: null,
  scenarioTargetRentEndDate: null,
  //
  scenarioTargetCorporationYn: false,
  scenarioTargetCorporationName: null,
  scenarioTargetCorporationNo: null,
  //
  scenarioTargetRegisterUserType: null,
  scenarioTargetOwnerName: null,
  scenarioTargetOwnerGender: null,
  scenarioTargetOwnerBirth: null,
  scenarioTargetOwnerPhoneNo: null,
  scenarioTargetOwnerRegisterName: null,
  scenarioTargetOwnerRegisterPhoneNo: null,
  //
  scenarioTargetUserAuthId: null,
  //
  brokerListLoadingYn: false,
};

const removeMarkerCache = (state, resetStateOnly = false) => {
  if (resetStateOnly) {
  } else {
    let i = 0;
    const tList = ['cacheMainMarker'].map((v) => {
      const t0 = Object.values(state[v]);
      i = Math.max(i, t0.length);
      return t0;
    });
    while (i--) {
      let tMarker;
      // cacheMainMarker
      tMarker = tList[0][i];
      tMarker && tMarker.map ? tMarker.setMap(null) : 0;
    }
  }

  return {
    ...state,
    cacheMainMarker: {},
    cacheMainMarkerList: [],
  };
};
export default function sellingReducer(state = initialState, action) {
  switch (action.type) {
    case t.SELLING_SET_MAP: {
      return {
        ...state,
        map: action['payload'],
      };
    }
    case t.SET_UI_MAP_STYLE_DATA: {
      console.group('SET_UI_MAP_STYLE_DATA');
      const { key, value } = action['payload'];
      const mapStyle = state.ui.lt.mapStyle;
      mapStyle[key].use = value;
      console.log('지도의 맵스타일과 레이어를 state.ui.lt.mayStyle에 근거하여 갱신합니다.');
      console.table(mapStyle);
      console.groupEnd('SET_UI_MAP_STYLE_DATA');
      return {
        ...state,
      };
    }
    case t.SET_CURRENT_REGION_DATA: {
      console.group('action : SET_CURRENT_REGION_DATA');
      const newData = action['payload'];
      state.currentRegionData = newData;
      console.log('현재 지역정보 상태를 갱신합니다.');
      console.groupEnd('action : SET_CURRENT_REGION_DATA');
      return {
        ...state,
      };
    }
    case t.SELLING_SET_PC_MENU_IDX: {
      return {
        ...state,
        pcMenuIDX: action['payload'],
      };
    }
    case t.SELLING_SET_MENU_IDX: {
      richLocalStorage.setItem('selling_mode', action['payload']);

      return {
        ...state,
        menuIDX: action['payload'],
      };
    }
    case t.SELLING_SET_UI: {
      return {
        ...state,
        ui: { ...action['payload'] },
      };
    }
    case t.SELLING_SET_MAP_MODE_YN: {
      return {
        ...state,
        mapModeYn: action['payload'],
      };
    }
    case t.SELLING_SET_LAST_MAP_DATA: {
      console.group('action : SET_LAST_MAP_DATA');
      const newData = action['payload'];
      console.log(newData);
      console.groupEnd('action : SET_LAST_MAP_DATA');

      return {
        ...state,
        lastMapData: newData,
        noticeInfos: newData?.result?.noticeInfos || state.noticeInfos,
      };
    }

    case t.SELLING_SET_CURRENT_REGION_DATA: {
      console.group('action : SET_CURRENT_REGION_DATA');
      const newData = action['payload'];
      state.currentRegionData = newData;
      console.log('현재 지역정보 상태를 갱신합니다.');
      console.groupEnd('action : SET_CURRENT_REGION_DATA');
      return {
        ...state,
      };
    }
    //////////////////////////////////////////////////////
    case t.SET_BROKER_LIST_LOADING_YN: {
      return {
        ...state,
        brokerListLoadingYn: action['payload'],
      };
    }
    case t.SUCCESS_BROKER_ITEM_LIST: {
      const { result, targetDataKey } = action['payload'];
      const { dataList } = result;
      const { managementDataList, talkDataList, favoriteDataList } = state.brokerItemInfo;
      return {
        ...state,
        brokerItemInfo: {
          ...result,
          managementDataList,
          talkDataList,
          favoriteDataList,
          [targetDataKey]: dataList,
        },
        brokerListLoadingYn: false,
      };
    }
    case t.SUCCESS_USER_ITEM_LIST: {
      return {
        ...state,
        isInitialized: true,
        userItemList: action['payload'],
      };
    }
    case t.RESET_ALL_ITEM_LIST: {
      return {
        ...state,
        allItemList: [],
      };
    }
    case t.SUCCESS_ALL_ITEM_LIST: {
      return {
        ...state,
        allItemList: state.allItemList.concat(action['payload']),
      };
    }
    case t.SET_SCENARIO_TARGET_USER_AUTH_ID:
      return {
        ...state,
        scenarioTargetUserAuthId: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_FLOOR:
      return {
        ...state,
        scenarioTargetFloor: action['payload'],
      };
    case t.SET_SCENARIO_SELL_TYPE:
      return {
        ...state,
        scenarioSellType: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_REGISTER_USER_TYPE:
      return {
        ...state,
        scenarioTargetRegisterUserType: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_CORPORATION_YN:
      return {
        ...state,
        scenarioTargetCorporationYn: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_CORPORATION_NAME:
      return {
        ...state,
        scenarioTargetCorporationName: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_CORPORATION_NO:
      return {
        ...state,
        scenarioTargetCorporationNo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_OWNER_NAME:
      return {
        ...state,
        scenarioTargetOwnerName: action['payload'],
      };

    case t.SET_SCENARIO_TARGET_OWNER_BIRTH:
      return {
        ...state,
        scenarioTargetOwnerBirth: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_OWNER_PHONE_NO:
      return {
        ...state,
        scenarioTargetOwnerPhoneNo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_OWNER_REGISTER_NAME:
      return {
        ...state,
        scenarioTargetOwnerRegisterName: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_OWNER_REGISTER_PHONE_NO:
      return {
        ...state,
        scenarioTargetOwnerRegisterPhoneNo: action['payload'],
      };
    //////
    case t.SET_SCENARIO_TARGET_RENT_TYPE:
      return {
        ...state,
        scenarioTargetRentType: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_RENT_PRICE:
      return {
        ...state,
        scenarioTargetRentPrice: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_RENT_MONTHLY_PRICE:
      return {
        ...state,
        scenarioTargetRentMonthlyPrice: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_RENT_END_DATE:
      return {
        ...state,
        scenarioTargetRentEndDate: action['payload'],
      };
    case t.RESET_STATE:
      return {
        ...initialState,
        menuIDX: state.menuIDX,
        pcMenuIDX: state.pcMenuIDX,
      };
    case t.SET_SCENARIO_TARGET_DATA:
      return {
        ...state,
        scenarioTargetData: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_DONG_INFO:
      return {
        ...state,
        scenarioTargetDongInfo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_HO_INFO:
      return {
        ...state,
        scenarioTargetHoInfo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_PYEONG_INFO:
      return {
        ...state,
        scenarioTargetPyeongInfo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_PYEONG_TYPE_INFO:
      return {
        ...state,
        scenarioTargetPyeongTypeInfo: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_WISH_TYPE:
      return {
        ...state,
        scenarioTargetWishType: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_WISH_PRICE:
      return {
        ...state,
        scenarioTargetWishPrice: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_WISH_MONTHLY_PRICE:
      return {
        ...state,
        scenarioTargetWishMonthlyPrice: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_WISH_DATE:
      return {
        ...state,
        scenarioTargetWishDate: action['payload'],
      };
    case t.SET_SCENARIO_TARGET_WISH_DATE_TYPE:
      return {
        ...state,
        scenarioTargetWishDateType: action['payload'],
      };

    default:
      return state;
  }
}
