/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type RadarDirectionType = (typeof RadarDirectionType)[keyof typeof RadarDirectionType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RadarDirectionType = {
  EAST: 'EAST',
  WEST: 'WEST',
  NORTH: 'NORTH',
  SOUTH: 'SOUTH',
  NORTH_EAST: 'NORTH_EAST',
  SOUTH_EAST: 'SOUTH_EAST',
  NORTH_WEST: 'NORTH_WEST',
  SOUTH_WEST: 'SOUTH_WEST',
} as const;
