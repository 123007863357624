import type { IBjdInfo } from 'jhSrc/types/bjd';

export type TUserNotificationDanjiSetting = {
  type: 'danji';
  id: string;
  name: string;
  bjdInfo: IBjdInfo;
  location: { x: number; y: number };
  meme: { active: boolean };
  jeonwolse: { active: boolean };
  story: { active: boolean };
  goodnews: { active: boolean };
};

export type TUserNotificationParcelSetting = {
  type: 'parcel';
  id: string;
  name: string;
  bjdInfo: IBjdInfo;
  location: { x: number; y: number };
  schedule: { active: boolean };
  story: { active: boolean };
  goodnews: { active: boolean };
};

// 새거
export interface UserNotificationDanjiSetting {
  danjiId: string;
  danji: string;
  pyeongType: number;
  bjdCode: string;
  bjdInfoDto: IBjdInfo;
  predictInfoDto: {
    bjdCode: string;
    score: number;
    rank: number;
    changeRate1m: number;
    changeRate6m: number;
    changeRate12m: number;
    pricePredictRate: number;
  };
  tradeTypeCode: null;
  avgPrice: number;
  avgPrice3mAgo: number;
  jeonseAvgPrice: number;
  jeonseAvgPrice3mAgo: number;
  location: { x: number; y: number };
}

export type TUserNotificationSetting = TUserNotificationDanjiSetting | TUserNotificationParcelSetting;

export default class UserNotification {
  setting = {
    list: [] as TUserNotificationSetting[],
  };

  constructor(profileFromServer?: any) {
    if (profileFromServer) {
      this.setting.list = profileFromServer.setting.list
        .map((v) => {
          const type = ['unknown', 'danji', 'parcel'][v.favoriteCode] ?? 'danji';

          const setting: any = {
            type,
            id: v.danjiId ?? '',
            name: v.danji ?? '',
            pyeong: v.pyeongType,
            bjdInfo: {
              code: v.bjdInfoDto.bjdCode ?? '',
              sd: v.bjdInfoDto.sd ?? '',
              sgg: v.bjdInfoDto.sgg ?? '',
              emd: v.bjdInfoDto.emd ?? '',
            },
            location: {
              x: v.location.x ?? NaN,
              y: v.location.y ?? NaN,
            },
            avgPrice: v.avgPrice,
            avgPrice3mAgo: v.avgPrice3mAgo,
            jeonseAvgPrice: v.jeonseAvgPrice,
            jeonseAvgPrice3mAgo: v.jeonseAvgPrice3mAgo,
          };

          // if (type === 'danji') {
          //   Object.assign(setting, {
          //     meme: { active: false },
          //     jeonwolse: { active: false },
          //     goodnews: { active: false },
          //     story: { active: false },
          //   });

          //   (v.alarmCodeList ?? []).forEach((code) => {
          //     if (code === 'MEME') {
          //       setting.meme.active = true;
          //     } else if (code === 'JEONSE') {
          //       setting.jeonwolse.active = true;
          //     } else if (code === 'GOODNEWS') {
          //       setting.goodnews.active = true;
          //     } else if (code === 'COMMUNITY') {
          //       setting.story.active = true;
          //     }
          //   });
          // } else

          if (type === 'parcel') {
            Object.assign(setting, {
              schedule: { active: false },
              story: { active: false },
              goodnews: { active: false },
            });

            (v.alarmCodeList ?? []).forEach((code) => {
              if (code === 'SCEHDULE') {
                setting.schedule.active = true;
              } else if (code === 'GOODNEWS') {
                setting.goodnews.active = true;
              } else if (code === 'COMMUNITY') {
                setting.story.active = true;
              }
            });
          }

          return setting;
        })
        .filter((v) => UserNotification.TYPE_LIST.includes(v.type));
    }
  }

  static TYPE_LIST = ['danji', 'parcel'];
}
