import type { IFetchRichgoApiOptions } from './createRichFetch';

import createRichFetch from './createRichFetch';

const richFetch = createRichFetch();

export const richFetchJson = (url: string, options?: IFetchRichgoApiOptions) =>
  richFetch(url, options).then((response) => response.json());

export default richFetch;
