import routing from 'hostSrc/_initDatas/routing';

import { RouteKey, RouteWithType } from './types';

export default function getRoutes(): Record<RouteKey, RouteWithType> {
  const routes = {} as Record<RouteKey, RouteWithType>;

  Object.entries(routing).forEach(([groupKey, itemHash]) => {
    const type = groupKey === 'popups' ? 'popup' : groupKey === 'dialogs' ? 'dialog' : 'page';
    Object.entries(itemHash).forEach(([key, item]) => {
      const path = item.path || key;
      routes[key] = {
        key: key,
        ...item,
        path: path.startsWith('/') ? path : `/${path}`,
        type,
      };
    });
  });

  return routes;
}
