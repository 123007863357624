/**
 * @prettier
 */
import RnManager from '../RnManager';

/**
 * localStorage에 뭔가를 할당할때 RN에 항상 최신 localStorage값 넣어줘야함 ( ios에서 휘발됨 )
 */
export function setLocalStorageItem(key: string, value: string) {
  localStorage.setItem(key, value);
  RnManager.updateNewLocalStorageAtReactNative(JSON.stringify(localStorage ?? {}) || '');
}

export function getLocalStorageItem(key: string) {
  return localStorage.getItem(key) ?? null;
}

/**
 * localStorage에 뭔가를 할당할때 RN에 항상 최신 localStorage값 넣어줘야함 ( ios에서 휘발됨 )
 */
export function removeLocalStorageItem(key: string) {
  localStorage.removeItem(key);
  RnManager.updateNewLocalStorageAtReactNative(JSON.stringify(localStorage ?? {}) || '');
}

const richLocalStorage = {
  getItem: getLocalStorageItem,
  setItem: setLocalStorageItem,
  removeItem: removeLocalStorageItem,
};

export default richLocalStorage;
