import { factoryCall } from 'coreSrc/base/pageStore';
import { richFetchJson } from 'coreSrc/core/js/utils/richFetch';

import t from './actionTypes';

const dummy = {
  goodNews: [
    {
      subType: 'Subway',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Railroad',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Road',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'City',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'TechCity',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Redevelop',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 0 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Rebuilding',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Remodeling',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
    {
      subType: 'Etc',
      byDistance: [
        { distance: 300, count: 10 },
        { distance: 600, count: 10 },
        { distance: 1000, count: 10 },
      ],
    },
  ],
  populationMoveInfos: {
    outOfAreaInfos: {
      부산시: 26_6192,
      경상남도: 14_9153,
      경상북도: 11_1153,
      경기도: 6_1163,
      인천시: 4_6153,
    },
    intoAreaInfos: {
      // 전출 정보
      부산시: 26_6192,
      울산시: 14_9153,
      마산시: 11_1153,
      노원구: 6_1163,
      도봉구: 4_6153,
    },
  },
};

const calls = {
  [t.REQUEST_GET_REGION_DETAIL]: factoryCall(t.REQUEST_GET_REGION_DETAIL, async (payload) => {
    const [resRoot, resChildren, resLeaders] = await Promise.all([
      richFetchJson(`/api/data/area-detail/root/${payload.bjdCode}`),
      richFetchJson(`/api/data/area-detail/children/${payload.bjdCode}`),
      richFetchJson(`/api/data/area-detail/leaders/${payload.bjdCode}`),
    ]);
    return {
      dummy,
      ...resRoot.result,
      ...resChildren.result,
      ...resLeaders.result,
    };
  }),
};

export default calls;
