export const RICHGO_API_HOST = process.env.NEXT_PUBLIC_RICHGO_API_HOST;

export const KAKAO_JAVASCRIPT = process.env.NEXT_PUBLIC_KAKAO_JAVASCRIPT;

export const APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID;
export const PUBLIC_NODE_ENV = process.env.NEXT_PUBLIC_NODE_ENV;

type AppEnv = 'local' | 'develop' | 'stage' | 'production';
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV as AppEnv;

export const isLocal = process.env.NEXT_PUBLIC_APP_ENV === 'local';
export const isDev = process.env.NEXT_PUBLIC_APP_ENV === 'develop';
export const isStage = process.env.NEXT_PUBLIC_APP_ENV === 'stage';
export const isProduction = process.env.NEXT_PUBLIC_APP_ENV === 'production';

export const API_MOCKING = process.env.NEXT_PUBLIC_API_MOCKING;

export const REACT_QUERY_DEVTOOLS = process.env.NEXT_PUBLIC_REACT_QUERY_DEVTOOLS;
