/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type RecommendedGrade = (typeof RecommendedGrade)[keyof typeof RecommendedGrade];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RecommendedGrade = {
  Now: 'Now',
  NotNow: 'NotNow',
  Hard: 'Hard',
  NotRecommended: 'NotRecommended',
} as const;
