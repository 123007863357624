if (globalThis.ReactNativeWebView) {
  const target = navigator.userAgent.includes('Android') ? document : window;

  globalThis.webview = {
    postMessage: function (message) {
      window.ReactNativeWebView.postMessage(message);
    },
    addMessageListener(callback) {
      target.addEventListener('message', callback);
    },
    removeMessageListener(callback) {
      target.removeEventListener('message', callback);
    },
  };
}
