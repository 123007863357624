import atob from 'atob';

class Jwt {
  /** @param {string} token */
  decode(token) {
    const [header, payload] = token.split('.');
    const jsonToken = {
      header: JSON.parse(atob(header)),
      payload: JSON.parse(atob(payload)),
    };

    return jsonToken;
  }

  isValid(token) {
    try {
      const exp = this.decode(token).payload.exp;
      return exp > Date.now() / 1000;
    } catch (error) {
      return false;
    }
  }
}

const jwt = new Jwt();

export default jwt;
