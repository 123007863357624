import { useMemo } from 'react';

import check from './check';

/**
 * agent 문자열 기반 디텍터
 * 클라상태기반 디텍팅은 안한다.
 *
 * @returns {{
 *   device: 'pc' | 'mobile' | 'tablet',
 *   browser: 'chrome' | 'ie' | 'edge' | 'whale' | 'naver' | 'opera' | 'firefox',
 *   browserVer: number,
 *   os: 'win' | 'android' | 'mac' | 'iphone' | 'ipad',
 *   osVer: number,
 *   agentStr: string,
 *   isMobile: boolean,
 *   isIOS: boolean,
 *   isSSR: boolean,
 * }}
 *
 * @examples
 * {
 *   device: 'pc',
 *   isMobile: false,
 *   browser: 'chrome',
 *   browserVer: 92,
 *   os: 'win'', // ssr 상태에서는 0
 *   osVer: 10, // ssr 상태에서는 0
 *   isIOS: false,
 *   agentStr: 'mozilla/5.0 (windows nt 10.0; win64; x64) applewebkit/537.36 (khtml, like gecko) chrome/92.0.4515.159 safari/537.36 edg/92.0.902.78',
 *   isSSR: true
 * }
 */
function useDetector(userAgent) {
  const detected = useMemo(() => {
    const result = {};

    if (!result['agentStr']) check(result, userAgent);
    {
      let t0 = {};
      for (const k in result) t0[k] = result[k];
    }
    return result;
  }, [userAgent]);

  return detected;
}

export default useDetector;
