import RnManager from '../RnManager';

export const INVALID_CREATE_COOKIE_KEY_OR_VALUE_ERROR_MESSAGE =
  '엔트리의 키 또는 값이 유효하지 않습니다';

export function createCookie(record: Record<string, string>) {
  const cookieJson = Object.entries(record)
    .map((entry) => entry.join('='))
    .join('; ');

  const chunks = cookieJson.split(';');
  for (const chunk of chunks) {
    if (Array.from(chunk.matchAll(/[=]/g)).length > 1)
      throw new Error(INVALID_CREATE_COOKIE_KEY_OR_VALUE_ERROR_MESSAGE);
  }

  return cookieJson;
}

export function parseCookie(cookie = ''): Record<string, string> {
  return cookie.split(';').reduce((accu, curr) => {
    if (curr.includes('=') === false) {
      accu[curr] = null;
      return accu;
    }

    const [key, value] = curr.split('=');
    accu[key.trim()] = value.trim();
    return accu;
  }, {});
}

/**
 * document.cookie에 뭔가를 할당할때 RN에 항상 최신 쿠키를 넣어줘야함
 * @param cookie - document.cookie
 */
export function setCookie(cookie: string) {
  document.cookie = cookie;
  RnManager.updateNewCookieAtReactNative(document.cookie);
}

const cookie = {
  create: createCookie,
  parse: parseCookie,
  set: setCookie,
};

export default cookie;
