export default function getUniqueId() {
  // 일단 가볍게 UUID 생성하고
  return 'xxxxxxxx_xxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 3) | 8;
    return v.toString(16);
  });
  // 정리되면 적당히 늘려봄
  // return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
  //   var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 3 | 8);
  //   return v.toString(16);
  // });
}
