/**
 * Do not edit manually.
 * Richgo M Web API
 * OpenAPI spec version: v1
 */

export type BoolThreeType = (typeof BoolThreeType)[keyof typeof BoolThreeType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BoolThreeType = {
  YES: 'YES',
  NO: 'NO',
  CHECK: 'CHECK',
} as const;
