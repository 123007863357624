import { useReducer } from 'react';

import makeActions from './makeActions.js';

const initialState = {
  startIDX: 0,
  list: [],
  useGeolocation: false,
};

const keyList = [
  {
    key: 'addWindow',
  },
  {
    key: 'addWindowAt',
  },
  {
    key: 'removeWindow',
  },
  {
    key: 'removeLastWindow',
  },
  {
    key: 'removeAllAndAddWindow',
  },
  {
    key: 'removeAll',
  },
  {
    key: 'setUseGeolocation',
  },
];

function reducer(state, action) {
  // console.log(state, action);
  switch (action.type) {
    case 'addWindow':
      state.list.push(action.payload);
      return {
        ...state,
      };
    case 'addWindowAt':
      if (action.payload.targetMarkerWindowIdx === 0 && state.list.length) state.list.length = 0;
      state.list[action.payload.targetMarkerWindowIdx] = action.payload;
      return {
        ...state,
      };
    case 'removeWindow':
      state.list.splice(action.payload, 1);
      return {
        ...state,
      };
    case 'removeAll':
      state.list.length = 0;
      return {
        ...state,
      };
    case 'removeAllAndAddWindow':
      state.list.length = 0;
      state.list.push(action.payload);
      return {
        ...state,
      };
    case 'removeLastWindow':
      state.list.pop();
      return {
        ...state,
      };
    case 'setUseGeolocation':
      state.useGeolocation = action.payload;
      return {
        ...state,
      };
    default:
      throw new Error('Unsupported action type:', action.type);
  }
}

const useContextWindowReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = makeActions(useContextWindowReducer, keyList, dispatch);
  return {
    state,
    actions,
  };
};
export default useContextWindowReducer;
