import { useEffect } from 'react';

import Head from 'next/head';

import useSystem from 'coreSrc/base/systemManager/useSystem';

import { KAKAO_JAVASCRIPT, RICHGO_API_HOST } from 'coreSrc/core/js/env';

import useSeo from './useSeo';

const NAVER_APP_CLIENT_ID = 'sf0zonfd2x';

const KAKAO_APP_ID = KAKAO_JAVASCRIPT;

interface IWebAppHeadProps {
  pageProps: {
    seo?: {
      url: string;
      title: string;
      description: string;
    };
  };
}

export default function WebAppHead(props: IWebAppHeadProps) {
  const system = useSystem();

  const seoMeta = useSeo(props.pageProps.seo);

  const isHana = system.partner.getType() === 'hana';
  const isNh = system.partner.getType() === 'nh';

  let t0 = 'https://dev12-mbp.hanabank.com:18080/mbp/resource/library/miaps/miaps_hybrid.js';
  let t1 = 'https://dev12-mbp.hanabank.com:18080/mbp/resource/js/common/pbk_mbp_partner.js';

  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
    t0 = 'https://mbp.hanabank.com/mbp/resource/library/miaps/miaps_hybrid.js';
    t1 = 'https://mbp.hanabank.com/mbp/resource/js/common/pbk_mbp_partner.js';
  }
  useEffect(() => {
    const list = [
      // 'https://cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/variable/woff2/SUIT-Variable.css',
      '/css/font.css',
      '/css/carousel.min.css',
      '/css/rc_slider.css',
      '/css/ReactToastify.css',
      '/css/toastManagerStyle.css',
      '/css/ReactSpringBottomSheet.css',
      '/css/toastui-editor-viewer.css',
    ];
    const linkList = list.map((v) => {
      const link = document.createElement('link');
      link.href = v;
      link.rel = 'preload';
      link.as = 'style';
      link.onload = () => {
        link.rel = 'stylesheet';
      };
      document.head.appendChild(link);
      return link;
    });
    // <link
    //   href="https://cdn.jsdelivr.net/gh/sunn-us/SUIT/fonts/variable/woff2/SUIT-Variable.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/carousel.min.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/rc_slider.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/ReactToastify.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/toastManagerStyle.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/ReactSpringBottomSheet.css"
    //   rel="stylesheet"
    // />
    // <link
    //   href="/css/toastui-editor-viewer.css"
    //   rel="stylesheet"
    // />
    return () => {
      linkList.forEach((link) => document.head.removeChild(link));
    };
  }, []);
  return (
    <Head>
      {/* SEO */}
      {!!seoMeta.url && (
        <>
          <link
            rel="canonical"
            href={seoMeta.url}
          />
          <title>{seoMeta.title}</title>
          <meta
            name="description"
            content={seoMeta.description}
          />
          <meta
            name="keywords"
            content={seoMeta.keywords}
          />
        </>
      )}

      {/* Google Ad Sense */}

      {/*<script*/}
      {/*  async*/}
      {/*  src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8195936551450232"*/}
      {/*  crossOrigin="anonymous"*/}
      {/*/>*/}

      <link
        rel="preconnect"
        href="https://static.hotjar.com"
        crossOrigin={'anonymous'}
      />
      <link
        rel="preconnect"
        href="https://firebase.googleapis.com"
        crossOrigin={'anonymous'}
      />
      <link
        rel="preconnect"
        href="http://nrbe.map.naver.net"
        crossOrigin={'anonymous'}
      />
      {/* 뷰포트 설정 */}
      <meta
        name="apple-mobile-web-app-capable"
        content="yes"
      />
      <meta
        name="mobile-web-app-capable"
        content="yes"
      />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="#3B82F6"
      />

      {/* 애플 로그인 SDK */}
      <script
        defer
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
      ></script>

      {/* 네이버 SEO */}
      <meta
        name="naver-site-verification"
        content="2ea079bbb7580c53309a92936f21eca76cc052a3"
      />

      {/* 구글 SEO */}
      <meta
        name="google-site-verification"
        content="hrKWop62L-fvZJBYkyz2ouwqZPeoY6udknR67m5It3Q"
      />

      {seoMeta.title && (
        <meta
          property="og:type"
          content="website"
        />
      )}
      {seoMeta.siteName && (
        <meta
          property="og:site_name"
          content={seoMeta.siteName}
        />
      )}
      {seoMeta.title && (
        <meta
          property="og:title"
          content={seoMeta.title}
        />
      )}
      {seoMeta.description && (
        <meta
          property="og:description"
          content={seoMeta.description}
        />
      )}
      {seoMeta.url && (
        <meta
          property="og:url"
          content={seoMeta.url}
        />
      )}

      {seoMeta.imageSrc && (
        <meta
          property="og:image"
          content={seoMeta.imageSrc}
        />
      )}

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png?v=2"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png?v=2"
      />
      <link
        rel="manifest"
        href="/site.webmanifest?v=2"
      />
      <link
        rel="mask-icon"
        href="/safari-pinned-tab.svg?v=2"
        color="#3b82f6"
      />
      <link
        rel="shortcut icon"
        href="/favicon.ico?v=2"
      />

      <meta
        name="apple-mobile-web-app-title"
        content="리치고"
      />
      <meta
        name="application-name"
        content="리치고"
      />
      <meta
        name="msapplication-TileColor"
        content="#ffffff"
      />
      <meta
        name="theme-color"
        content="#ffffff"
      />

      {/* 흠 이건 index에서만 로딩하면 될듯하고 - 하나은행 라이브러리 로딩 */}
      {isHana && (
        <>
          <script src={t0}></script>
          <script src={t1}></script>
        </>
      )}

      {isNh && (
        <script
          type="text/javascript"
          src="/nh.js"
        ></script>
      )}

      <script
        defer
        type="text/javascript"
        src={`https://oapi.map.naver.com/openapi/v3/maps.js?ncpClientId=${NAVER_APP_CLIENT_ID}&submodules=panorama,geocoder,visualization`}
      ></script>

      <script
        defer
        type="text/javascript"
        src={'/MarkerClustering.js'}
      ></script>
      <script
        defer
        type="text/javascript"
        src={'/MeasureDistance.js'}
      ></script>
      <script
        defer
        type="text/javascript"
        src={`//dapi.kakao.com/v2/maps/sdk.js?appkey=${KAKAO_APP_ID}&libraries=services`}
      ></script>
      <link
        rel="preconnect"
        href="https://js.tosspayments.com"
        crossOrigin={'anonymous'}
      />
      <script
        defer
        src="https://js.tosspayments.com/v1/payment"
      ></script>
      <script
        defer
        src="https://js.tosspayments.com/v1/payment-widget"
      ></script>

      {!system.detector.isMobile && system.router.asPath.indexOf('/pc') > -1 && (
        <script
          // defer 태블루는사용하면안됨
          type="text/javascript"
          src="https://mas-tableau.richgo.ai/javascripts/api/tableau-2.min.js"
        ></script>
      )}
      <script
        defer
        src="https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js"
        integrity="sha384-kDljxUXHaJ9xAb2AzRd59KxjrFjzHa5TAoFQ6GbYTCAG0bjM55XohjjDT7tDDC01"
        crossOrigin="anonymous"
      ></script>

      <meta
        name="format-detection"
        content="telephone=no"
      />
    </Head>
  );
}
