import { useReducer } from 'react';

import { upperSnakeToCamel } from 'coreSrc/core/js/draft';

import * as actionTypes from 'hostSrc/pageStore/actionTypes';
import * as reducers from 'hostSrc/pageStore/reducers';
import * as saga from 'hostSrc/pageStore/saga';

function rootReducer(state, action) {
  return Object.entries(reducers).reduce(
    (acc, [key, reducer]) => {
      acc[key] = reducer(acc[key], action);
      return acc;
    },
    { ...state }
  );
}

// function logMiddleware(dispatch, action) {
//   console.log('debug ' + `%c${action.type}`, 'font-weight:bold;color:skyblue', {
//     payload: action.payload,
//   });
// }

function sagaMiddleware(dispatch, action) {
  Object.values(saga).forEach((sagaHash) => {
    sagaHash[action.type]?.(dispatch, action.payload);
  });
}

export default function usePageStoreReducer(...middlewares) {
  const [state, dispatch] = useReducer(rootReducer, rootReducer({}, {}));

  const dispatchWithMiddleware = (action) => {
    [/*logMiddleware, */ sagaMiddleware, ...middlewares].forEach((middleware) =>
      middleware(dispatchWithMiddleware, action)
    );
    dispatch(action);
  };

  const actions = Object.entries(actionTypes).reduce((acc, [key, typeHash]) => {
    const newKey = key.replace('ActionTypes', '');
    acc[newKey] = Object.values(typeHash).reduce((ac, type) => {
      ac[upperSnakeToCamel(type)] = (payload) => dispatchWithMiddleware({ type, payload });
      return ac;
    }, {});
    return acc;
  }, {});

  return { state, actions };
}
