import Link, { LinkProps } from 'next/link';

import { RouteParams, RouteKey } from './types';
import getPath from './getPath';
import getRoutes from './getRoutes';

// FIXME: kangseongofdk
// - useRouteReducer 에서도 같은 일을 하고 있는데 이거 한 번에 하려면 어떤 방법이 좋을까?
const _routes = getRoutes();

type RouteLinkProps = React.PropsWithChildren<LinkProps> & {
  path: RouteKey;
  params: RouteParams;
};
function RouteLink({ path, params, href: _href, passHref = true, ...props }: RouteLinkProps) {
  const href = path ? getPath(_routes[path], params) : _href;

  if (href === null) {
    throw new Error(`등록되지 않은 path 로 RouteLink 사용 (path="${path}")`);
  }

  return <Link href={href} passHref={passHref} {...props} />;
}
export default RouteLink;
