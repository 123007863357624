import { parseCookie } from 'coreSrc/core/js/utils/cookie';
import jwt from 'coreSrc/core/js/utils/jwt/jwt';
import richFetch from 'coreSrc/core/js/utils/richFetch';

import { getUserFavoriteSummaryTotalList } from 'hostSrc/apis/endpoints/user/user';

import User from './User';
import UserAuth from './authManager/UserAuth';
import { RICHGO_API_REFRESH_TOKEN_KEY } from './authManager/const';
import useAuthReducer from './authManager/useAuthReducer';
import UserFavorites from './favorites/UserFavorites';
import useFavoritesReducer from './favorites/useFavoritesReducer';
import UserNotification from './notification/UserNotification';
import useNotificationReducer from './notification/useNotificationReducer';
import UserProfile from './profileManager/UserProfile';
import useProfileReducer from './profileManager/useProfileReducer';

import type { Updater } from 'use-immer';
import { useImmer } from 'use-immer';

interface IUserSystem extends User {
  loading: boolean;
}

export default function useUserSystemReducer(user: User) {
  const [state, setState] = useImmer<IUserSystem>(() => {
    return {
      loading: false,
      notification: user?.notification ?? new UserNotification(),
      profile: user?.profile ?? new UserProfile(),
      auth: user?.auth ?? new UserAuth(),
      favorites: user?.favorites ?? new UserFavorites(),
    };
  });

  const actions = createActions(state, setState);

  const favorites = useFavoritesReducer(state.favorites, actions);
  const notification = useNotificationReducer(state.notification, actions);
  const profile = useProfileReducer(state.profile, actions);
  const auth = useAuthReducer(state.auth, actions);

  return Object.freeze({
    cancelLoading: actions.cancelLoading,
    update: actions.update,
    loading: state.loading,
    notification,
    profile,
    auth,
    favorites,
  });
}

export type TUserSystemActions = ReturnType<typeof createActions>;

function createActions(state: IUserSystem, setState: Updater<IUserSystem>) {
  function cancelLoading() {
    setState((draft) => {
      draft.loading = false;
    });
  }

  /** 클라이언트 사이드에서만 호출 가능 */
  async function update() {
    setState((draft) => {
      draft.loading = true;
    });

    try {
      const cookie = parseCookie(document.cookie);
      const richgoApiRefreshToken = cookie[RICHGO_API_REFRESH_TOKEN_KEY];
      const isValidRichgoApiRefreshToken = jwt.isValid(richgoApiRefreshToken);

      if (isValidRichgoApiRefreshToken) {
        const [profileResponse, favoriteListResponse, favoritesNew] = await Promise.all([
          // richFetch('/user/favorite/ALL'),
          richFetch('/user/V2/profile'),
          richFetch('/user/favorite-summary-list'),
          getUserFavoriteSummaryTotalList(),
        ]);

        // const notificationJson = await notificationResponse.json();
        const profileResponseJson = await profileResponse.json();
        const favoriteListJson = await favoriteListResponse.json();
        const newUserProfile = new UserProfile(profileResponseJson.result);

        setState((draft) => {
          draft.loading = false;
          draft.notification = new UserNotification({
            setting: { list: favoriteListJson.result.dataList },
          });
          draft.profile = newUserProfile;
          draft.auth = new UserAuth({ isLoggedIn: true });
          draft.favorites = new UserFavorites(favoritesNew.result);
        });

        return newUserProfile;
      } else {
        setState((draft) => {
          draft.loading = false;
          draft.notification = new UserNotification();
          draft.profile = new UserProfile();
          draft.auth = new UserAuth();
          draft.favorites = new UserFavorites();
        });
      }

      // throw new Error('refresh 토큰이 유효하지 않습니다');
    } catch (error) {
      setState((draft) => {
        draft.loading = false;
        draft.notification = new UserNotification();
        draft.profile = new UserProfile();
        draft.auth = new UserAuth();
        draft.favorites = new UserFavorites();
      });
      throw error;
    }
  }

  function setLoading(loading: boolean) {
    setState((draft) => {
      draft.loading = loading;
    });
  }

  return {
    update,
    setLoading,
    cancelLoading,
  };
}
