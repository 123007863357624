import { APP_ENV } from 'coreSrc/core/js/env';

import isIncluded from 'hostSrc/utils/isIncluded';

export default class NetModel_Slack {
  async createNotification(type: keyof typeof TARGET_SERVICE, data) {
    try {
      if (isIncluded(['local'] as const, APP_ENV)) {
        return;
      }

      const notification = {
        text: '디버깅로그',
        blocks: REPLACER[type] ? [...REPLACER[type](data), BLOCK.separator] : [],
      };

      const url = `https://hooks.slack.com/services/${TARGET_SERVICE[type]}`;

      await fetch(url, {
        method: 'POST',
        mode: 'no-cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(notification),
      });
    } catch (error) {
      console.log(error);
    }
  }
}

const BLOCK = {
  separator: {
    type: 'section',
    text: { type: 'mrkdwn', text: '='.repeat(20) },
  },

  header(title) {
    return {
      type: 'header',
      text: {
        type: 'plain_text',
        text: `| ${title}`,
        emoji: true,
      },
    };
  },

  text(title, description) {
    return {
      type: 'section',
      text: { type: 'mrkdwn', text: (title ? `· ${title} : ` : '') + description },
    };
  },

  plainText(text) {
    return {
      type: 'section',
      text: { type: 'mrkdwn', text: `${text}` },
    };
  },

  link(title, url) {
    return {
      type: 'section',
      text: { type: 'mrkdwn', text: `<${url}|${title || '링크'}>` },
    };
  },

  markdown(title, text) {
    return {
      type: 'section',
      text: {
        type: 'mrkdwn',
        text: (title ? `· ${title} ` : '') + `\`\`\`${text || '-'}\`\`\``,
      },
    };
  },
};

const REPLACER: Record<keyof typeof TARGET_SERVICE, (data: any) => any[]> = {
  LOG_WEBVIEW_REDIRECT(data) {
    return [
      BLOCK.header(`웹뷰 리다이렉트 로그 - ${data?.env}`),
      BLOCK.text('host', data?.host),
      BLOCK.text('isError', data?.isError),
      BLOCK.text('url', data?.url),
      BLOCK.text('asPath', data?.asPath),
      BLOCK.text('e_data', data?.e_data),
      BLOCK.text('error', data?.error),
      BLOCK.markdown('userProfile', data?.userProfile ?? 'null'),
      BLOCK.markdown('객체 전체 (stringify)', data?.message || 'null'),
    ];
  },

  LOG_LOCAL_STORAGE(data) {
    return [
      BLOCK.header(`로컬스토리지 에러 로그 - ${data?.env}`),
      data?.host && BLOCK.text('host', data?.host),
      data?.isError && BLOCK.text('isError', data?.isError),
      data?.url && BLOCK.text('url', data?.url),
      data?.asPath && BLOCK.text('asPath', data?.asPath),
      data?.e_data && BLOCK.text('e_data', data?.e_data),
      data?.error && BLOCK.text('error', data?.error),
      data?.key && BLOCK.text('key', data?.key),
      BLOCK.markdown('userProfile', data?.userProfile ?? 'null'),
      BLOCK.markdown('객체 전체 (stringify)', data?.message || 'null'),
    ].filter(Boolean);
  },

  LOG_TRACKING_ERROR(data) {
    return [
      BLOCK.header(`에러 추적 로그 - ${data?.env}`),
      data?.url && BLOCK.text('url', data?.url),
      data?.danjiId && BLOCK.text('danjiId', data?.danjiId),
      data?.pyeongType && BLOCK.text('pyeongType', data?.pyeongType),
      data?.tradeType && BLOCK.text('tradeType', data?.tradeType),
      data?.summary && BLOCK.text('summary', data?.summary),
      BLOCK.markdown('userProfile', data?.userProfile ?? 'null'),
      BLOCK.markdown('객체 전체 (stringify)', data?.message || 'null'),
    ].filter(Boolean);
  },
};

const TARGET_SERVICE = {
  LOG_WEBVIEW_REDIRECT: 'TJKM6FJDT/B0388KEJ72P/0nEtPkkbQ0wM1BOZ8NDVyarC',
  LOG_LOCAL_STORAGE: 'TJKM6FJDT/B0388KEJ72P/0nEtPkkbQ0wM1BOZ8NDVyarC',
  LOG_TRACKING_ERROR: 'TJKM6FJDT/B0388KEJ72P/0nEtPkkbQ0wM1BOZ8NDVyarC',
} as const;
