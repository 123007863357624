import { Fragment } from 'react';

import styled from 'styled-components';

// eslint-disable-next-line no-useless-escape
const STRONG_REGEXP = /\*{2}([^\*]+)\*{2}/;

function parse(source, Tag) {
  let copied = source;
  const items = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const matched = copied.match(STRONG_REGEXP);
    if (!matched) {
      items.push({ content: copied });
      break;
    }
    if (matched.index > 0) {
      items.push({
        content: copied.slice(0, matched.index),
      });
    }
    items.push({
      bold: true,
      content: copied.slice(matched.index, matched.index + matched[0].length),
    });
    copied = copied.slice(matched.index + matched[0].length);
  }

  return (
    <Tag>
      {items.map((item, i) => (
        <Fragment key={i}>
          {item.bold ? <strong>{item.content.match(STRONG_REGEXP)[1]}</strong> : <>{item.content}</>}
        </Fragment>
      ))}
    </Tag>
  );
}

function ParagraphWithStrong({ text }) {
  return parse(text, 'p');
}

function HeadingWithStrong({ text }) {
  const [, level, splitted] = text.match(/(^#{1,6})(.+)$/);
  return parse(splitted, `h${level.length}`);
}

type SimpleMdProps = {
  text: string;
  className?: string;
};
function SimpleMd({ text, ...props }: SimpleMdProps) {
  const lines = text.split('\n').map((p) => p.trim());
  return (
    <SD_Container {...props}>
      {lines.map((line, i) =>
        line.match(/^#{1,3}/) ? (
          <HeadingWithStrong key={i} text={line} />
        ) : (
          <ParagraphWithStrong key={i} text={line} />
        )
      )}
    </SD_Container>
  );
}

const SD_Container = styled.div``;

export default SimpleMd;
