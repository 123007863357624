import type { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

interface SpacerProps {
  type?: 'vertical' | 'horizontal';
  gap?: number;
  align?: CSSProperties['alignItems'];
  justify?: CSSProperties['justifyContent'];
  margin?: CSSProperties['margin'];
  padding?: CSSProperties['padding'];
  children?: ReactNode;
}

/**
 * 예전에 flex layout 사용 용도로 만들어놨던건데
 * 이번에 Flex컴포넌트를 별도로 분리하였으므로
 * padding, margin 등 spacing 목적으로만 사용하면 좋을듯 합니다
 * */
const Spacer = styled.div<SpacerProps>`
  display: flex;
  flex-direction: ${({ type }) => type === 'vertical' && 'column'};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  gap: ${({ gap = 0 }) => `${gap}px`};
  margin: ${({ margin = 0 }) => (typeof margin === 'number' ? `${margin}px` : margin)};
  padding: ${({ padding = 0 }) => (typeof padding === 'number' ? `${padding}px` : padding)};
`;

export default Spacer;
