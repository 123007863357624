import type { AppContext } from 'next/app';

import User from 'coreSrc/base/userSystemManager/user/User';
import UserAuth from 'coreSrc/base/userSystemManager/user/authManager/UserAuth';
import {
  RICHGO_API_ACCESS_TOKEN_KEY,
  RICHGO_API_REFRESH_TOKEN_KEY,
  RICHGO_API_REGISTER_TOKEN_KEY,
} from 'coreSrc/base/userSystemManager/user/authManager/const';
// import { getUserFavoriteSummaryTotalList } from 'hostSrc/apis/endpoints/user/user';
import UserFavorites from 'coreSrc/base/userSystemManager/user/favorites/UserFavorites';
import UserNotification from 'coreSrc/base/userSystemManager/user/notification/UserNotification';
import UserProfile from 'coreSrc/base/userSystemManager/user/profileManager/UserProfile';

import { createCookie, parseCookie } from 'coreSrc/core/js/utils/cookie';
import jwt from 'coreSrc/core/js/utils/jwt/jwt';
import createRichFetch from 'coreSrc/core/js/utils/richFetch/createRichFetch';

import { getUserFavoriteSummaryTotalList } from 'hostSrc/apis/endpoints/user/user';

import { readAccessToken } from 'jhSrc/apis/auth';

import type { IWebAppContext } from '..';
import ContextCookieHelper from './ContextCookieHelper';
import ContextRedirectHelper from './ContextRedirectHelper';
import ContextRichgoAuthHelper from './ContextRichgoAuthHelper';

import type { OutgoingHttpHeaders } from 'http';
import { UAParser } from 'ua-parser-js';

export default async function useAppContextMiddleware(appContext: AppContext) {
  const handlers: ((appContext: AppContext) => Promise<boolean | void> | (boolean | void))[] = [
    contextRedirectHandler,
    contextPartnerHandler,
    contextRichgoAuthHandler,
    fetchHandler,
    contextUserHandler,
    contextCookieHandler,
  ];

  for (const handler of handlers) {
    const result = await handler(appContext);
    if (result === false) break;
  }
}

async function contextRedirectHandler(appContext: AppContext) {
  if (!appContext.ctx.req) return;
  if (appContext.router.pathname === '/') {
    if (appContext?.ctx?.req?.headers?.['user-agent']) {
      const uaParser = new UAParser(appContext.ctx.req.headers?.['user-agent']);
      let isAppMode = false;
      if (appContext.router.query['appMode'] === 'true') {
        isAppMode = true;
      }
      console.log(
        '앱모드냐',
        appContext.router.query['appMode'],
        typeof appContext.router.query['appMode']
      );
      if (!isAppMode || !['Android', 'iOS'].includes(uaParser.getOS().name)) {
        const header: OutgoingHttpHeaders = {};
        const searchParams = ContextRedirectHelper.getSearchParams(appContext.ctx.req.url ?? '');
        header.Location = '/realty/richgoMap' + (searchParams ? '?' : '') + searchParams;
        appContext.ctx.res.writeHead(307, header);
        appContext.ctx.res.end();
        return false;
      }
    }

    const cookie = appContext?.ctx?.req?.headers?.cookie?.split('; ');

    const searchParams = ContextRedirectHelper.getSearchParams(appContext.ctx.req?.url ?? '');
    const header: OutgoingHttpHeaders = {};
    header.Location = '/realty/home' + (searchParams ? '?' : '') + searchParams;
    if (cookie) {
      header['Set-Cookie'] = cookie;
    }
    appContext.ctx.res.writeHead(307, header);
    appContext.ctx.res.end();
    return false;
  }

  if (appContext.router.asPath.startsWith('/pc')) {
    const userAgent = appContext.ctx.req.headers['user-agent'];
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        userAgent
      );

    if (isMobile) {
      const Location = ContextRedirectHelper.toHome();
      appContext.ctx.res.writeHead(301, { Location });
      appContext.ctx.res.end();
      return false;
    }
  }

  if (appContext.router.asPath.startsWith('/nh_intro')) {
    const Location = ContextRedirectHelper.toHome() + '?partner=nh';
    appContext.ctx.res.writeHead(301, { Location });
    appContext.ctx.res.end();
    return false;
  }

  if (appContext.router.asPath.startsWith('/nh_intro_allone')) {
    const Location = ContextRedirectHelper.toHome() + '?partner=nhAllOne';
    appContext.ctx.res.writeHead(301, { Location });
    appContext.ctx.res.end();
    return false;
  }

  if (appContext.router.asPath.startsWith('/hanaIntro')) {
    const Location = ContextRedirectHelper.toHome() + '?partner=hana';
    appContext.ctx.res.writeHead(301, { Location });
    appContext.ctx.res.end();
    return false;
  }

  if (appContext.ctx.req?.url?.includes('/danjiInvestTrust_KB')) {
    appContext.ctx.res.writeHead(301, {
      Location: await ContextRedirectHelper.toDanjiPath(appContext),
    });
    appContext.ctx.res.end();
    return false;
  }

  if ('samsung' in appContext.router.query) {
    if (!('redirect' in appContext.router.query)) {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('redirect', 'true');
      urlSearchParams.set('samsung', 'true');
      const token = appContext.router.query.token as string;
      urlSearchParams.set('token', token);
      appContext.ctx.res.writeHead(301, {
        Location: `/realty/my-house/myHouses?${urlSearchParams}`,
      });
      appContext.ctx.res.end();
      return false;
    }
  }

  if (appContext.router.pathname === '/danji') {
    const urlSearchParams = new URLSearchParams();

    if (appContext.router.query.samsung) {
      urlSearchParams.set('samsung', 'true');
    }

    if (appContext.router.query.isHanaCard) {
      urlSearchParams.set('isHanaCard', 'true');
    }

    if (appContext.router.query.pyeong_type) {
      urlSearchParams.set('pyeongType', appContext.router.query.pyeong_type as string);
    }

    const token = appContext.router.query.token as string;
    if (token) {
      urlSearchParams.set('token', token);
    }

    const searchParams = urlSearchParams.toString();

    const Location =
      `/realty/danji/${appContext.router.query.danji_id}` + (searchParams ? '?' : '') + searchParams;
    appContext.ctx.res.writeHead(301, { Location });
    appContext.ctx.res.end();
    return false;
  }

  if (
    appContext.router.asPath !== '/danji_detail.xml' &&
    appContext.router.asPath.startsWith('/danji') &&
    !appContext.router.asPath.startsWith('/danji_detail')
  ) {
    const urlSearchParams = new URLSearchParams();

    if (appContext.router.query.pyeong_type) {
      urlSearchParams.set('pyeongType', appContext.router.query.pyeong_type as string);
    }

    const searchParams = urlSearchParams.toString();

    const Location =
      `/realty/danji/${appContext.router.query.id}` + (searchParams ? '?' : '') + searchParams;
    appContext.ctx.res.writeHead(301, { Location });
    appContext.ctx.res.end();
    return false;
  }
}

function contextPartnerHandler(appContext: AppContext) {
  const partner = {
    type: 'none',
  };

  if (appContext.ctx.req) {
    const isHana =
      appContext.router.query?.partner === 'hana' ||
      appContext.ctx.req.headers.cookie?.includes('PARTNER=hana');
    if (isHana) {
      ContextCookieHelper.push(appContext, ['PARTNER=hana; path=/']);
      partner.type = 'hana';
      return void Object.assign(appContext.ctx, { partner });
    }

    const isNhAllOne =
      appContext.router.query?.partner === 'nhAllOne' ||
      appContext.ctx.req.headers.cookie?.includes('PARTNER=nhAllOne');
    if (isNhAllOne) {
      ContextCookieHelper.push(appContext, ['PARTNER=nhAllOne; path=/']);
      partner.type = 'nhAllOne';
      return void Object.assign(appContext.ctx, { partner });
    }

    const isNh =
      appContext.router.query?.partner === 'nh' ||
      appContext.ctx.req.headers.cookie?.includes('PARTNER=nh');
    if (isNh) {
      ContextCookieHelper.push(appContext, ['PARTNER=nh; path=/']);
      partner.type = 'nh';
      return void Object.assign(appContext.ctx, { partner });
    }

    const isSamsung =
      appContext.ctx.req.url.includes('samsung=true') ||
      appContext.ctx.req.headers.cookie?.includes('PARTNER=samsung');
    if (isSamsung) {
      partner.type = 'samsung';

      const searchParams = appContext.ctx.req.url.split('?')[1];
      const urlSearchParams = new URLSearchParams(searchParams);
      const token = urlSearchParams.get('token');

      const tokenType = jwt.decode(token);
      if (tokenType.payload.role === 'RefreshToken') {
        const cookieJson = parseCookie(appContext.ctx.req.headers.cookie);
        Object.assign(cookieJson, { [RICHGO_API_REFRESH_TOKEN_KEY]: token });
        appContext.ctx.req.headers.cookie = createCookie(cookieJson);
      } else if (tokenType.payload.role === 'RegisterToken') {
        const registerTokenEntries = [
          `${RICHGO_API_REGISTER_TOKEN_KEY}=${token}`,
          'samesite=Lax',
          'path=/',
        ];

        const accessTokenEntries = [`${RICHGO_API_ACCESS_TOKEN_KEY}=null`, 'max-age=0', 'path=/'];

        const refreshTokenEntries = [`${RICHGO_API_REFRESH_TOKEN_KEY}=null`, 'max-age=0', 'path=/'];

        ContextCookieHelper.push(appContext, [
          registerTokenEntries.join('; '),
          refreshTokenEntries.join('; '),
          accessTokenEntries.join('; '),
        ]);

        const cookieJson = parseCookie(appContext.ctx.req.headers.cookie);
        delete cookieJson[RICHGO_API_REFRESH_TOKEN_KEY];
        delete cookieJson[RICHGO_API_ACCESS_TOKEN_KEY];
        Object.assign(cookieJson, { [RICHGO_API_REFRESH_TOKEN_KEY]: token });
        appContext.ctx.req.headers.cookie = createCookie(cookieJson);
      }

      return void Object.assign(appContext.ctx, { partner });
    }

    console.log('appContext.ctx.req.url', appContext.ctx.req.url);
    // console.log('appContext.ctx.req.headers', appContext.ctx.req.headers);
    const isNewNH =
      appContext.ctx.req.url.includes('nh_token_info') ||
      appContext.ctx.req.headers.cookie?.includes('PARTNER=newNH');
    if (isNewNH) {
      partner.type = 'newNH';
      const searchParams = appContext.ctx.req.url.split('?')[1];
      const urlSearchParams = new URLSearchParams(searchParams);
      const token = urlSearchParams.get('nh_token_info');

      const tokenType = jwt.decode(token);
      if (tokenType.payload.role === 'RefreshToken') {
        const cookieJson = parseCookie(appContext.ctx.req.headers.cookie);
        Object.assign(cookieJson, { [RICHGO_API_REFRESH_TOKEN_KEY]: token });
        appContext.ctx.req.headers.cookie = createCookie(cookieJson);
      } else if (tokenType.payload.role === 'RegisterToken') {
        const registerTokenEntries = [
          `${RICHGO_API_REGISTER_TOKEN_KEY}=${token}`,
          'newNH=Lax',
          'path=/',
        ];

        const accessTokenEntries = [`${RICHGO_API_ACCESS_TOKEN_KEY}=null`, 'max-age=0', 'path=/'];
        const refreshTokenEntries = [`${RICHGO_API_REFRESH_TOKEN_KEY}=null`, 'max-age=0', 'path=/'];
        ContextCookieHelper.push(appContext, [
          registerTokenEntries.join('; '),
          refreshTokenEntries.join('; '),
          accessTokenEntries.join('; '),
        ]);

        const cookieJson = parseCookie(appContext.ctx.req.headers.cookie);
        delete cookieJson[RICHGO_API_REFRESH_TOKEN_KEY];
        delete cookieJson[RICHGO_API_ACCESS_TOKEN_KEY];
        Object.assign(cookieJson, { [RICHGO_API_REFRESH_TOKEN_KEY]: token });
        appContext.ctx.req.headers.cookie = createCookie(cookieJson);
      }

      return void Object.assign(appContext.ctx, { partner });
    }

    // const isDL = appContext.ctx.req.headers.referer.includes('landeasy');
    const isDL = appContext.ctx.req.url.includes('=landeasy');
    if (isDL) {
      ContextCookieHelper.push(appContext, ['PARTNER=DL; path=/']);
      partner.type = 'DL';
      return void Object.assign(appContext.ctx, { partner });
    }

    return void Object.assign(appContext.ctx, { partner });
  } else {
    // CSR 처리 로직
    const { query } = appContext.router;

    const newNHToken = query.nh_token_info;
    if (newNHToken) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const tokenType = jwt.decode(newNHToken);

      if (tokenType.payload.role === 'RefreshToken') {
        // You might want to use a state management solution or context API to manage cookie state on the client side.
        document.cookie = `${RICHGO_API_REFRESH_TOKEN_KEY}=${newNHToken}; path=/`;
      } else if (tokenType.payload.role === 'RegisterToken') {
        document.cookie = `${RICHGO_API_REGISTER_TOKEN_KEY}=${newNHToken}; path=/`;
        document.cookie = `${RICHGO_API_ACCESS_TOKEN_KEY}=null; max-age=0; path=/`;
        document.cookie = `${RICHGO_API_REFRESH_TOKEN_KEY}=null; max-age=0; path=/`;
      }
      partner.type = 'newNH';
    }
  }
  Object.assign(appContext.ctx, { partner });
}

async function contextRichgoAuthHandler(appContext: AppContext) {
  const isSsr = appContext.ctx.req ? true : false;

  if (isSsr) {
    const cookieString = ContextCookieHelper.getCookie(appContext);
    const cookie = parseCookie(cookieString);
    let richgoApiRefreshToken = cookie[RICHGO_API_REFRESH_TOKEN_KEY] ?? '';

    if (richgoApiRefreshToken) {
      const refreshTokenPayload = jwt.decode(richgoApiRefreshToken).payload;

      if (!refreshTokenPayload.ExternalId) {
        richgoApiRefreshToken = '';
      }
    }

    try {
      if (!richgoApiRefreshToken) throw new Error('refresh token을 가지고 있지 않습니다');
      const richgoApiAccessToken = await readAccessToken({ refreshToken: richgoApiRefreshToken });

      ContextRichgoAuthHelper.setRefreshToken(appContext, richgoApiRefreshToken);
      ContextRichgoAuthHelper.setAccessToken(appContext, richgoApiAccessToken);

      const SECOND = 1000;
      const MINUTE = 60 * SECOND;
      const HOUR = 60 * MINUTE;
      const DATE = 24 * HOUR;
      const YEAR = 365 * DATE;

      const refreshTokenEntries = [
        `${RICHGO_API_REFRESH_TOKEN_KEY}=${richgoApiRefreshToken}`,
        'path=/',
        `max-age=${(2 * YEAR) / SECOND}`,
        'samesite=Lax',
      ];

      const accessTokenEntries = [
        `${RICHGO_API_ACCESS_TOKEN_KEY}=${richgoApiAccessToken}`,
        'path=/',
        `max-age=${(2 * YEAR) / SECOND}`,
        'samesite=Lax',
      ];

      ContextCookieHelper.push(appContext, [
        refreshTokenEntries.join('; '),
        accessTokenEntries.join('; '),
      ]);
    } catch (error) {
      ContextCookieHelper.push(appContext, [
        `${RICHGO_API_REFRESH_TOKEN_KEY}=null; path=/; max-age=0;`,
        `${RICHGO_API_ACCESS_TOKEN_KEY}=null; path=/; max-age=0;`,
      ]);
    }
  }
}

function fetchHandler(appContext: AppContext) {
  const richFetch = createRichFetch(appContext);
  Object.assign(appContext.ctx, { richFetch });
}

async function contextUserHandler(appContext: IWebAppContext) {
  const isSsr = appContext.ctx.req ? true : false;
  if (isSsr) {
    try {
      const richgoApiAccessToken = ContextRichgoAuthHelper.getAccessToken(appContext);
      if (!richgoApiAccessToken) return assignUserContext(appContext);

      const [profileResponse, favoriteListResponse, favoritesNew] = await Promise.all([
        // appContext.ctx.richFetch('/user/favorite/ALL'),
        appContext.ctx.richFetch('/user/V2/profile'),
        appContext.ctx.richFetch('/user/favorite-summary-list'),
        getUserFavoriteSummaryTotalList({ serverSideFetch: appContext.ctx.richFetch }),
      ]);

      // if (notificationResponse.status !== 200) throw notificationResponse;
      if (profileResponse.status !== 200) throw profileResponse;
      if (favoriteListResponse.status !== 200) throw favoriteListResponse;
      if (favoritesNew.statusCode !== 200) throw favoritesNew;

      const favoriteListJson = await favoriteListResponse.json();
      const profileResponseJson = await profileResponse.json();

      const notification = new UserNotification({
        setting: { list: favoriteListJson.result.dataList },
      });
      const profile = new UserProfile(profileResponseJson.result);
      const auth = new UserAuth({ isLoggedIn: true });
      const favorites = new UserFavorites(favoritesNew.result);

      assignUserContext(appContext, {
        notification,
        profile,
        auth,
        favorites,
      });
    } catch (error) {
      console.error(error);
      assignUserContext(appContext);
    }
  }
}

function assignUserContext(appContext: AppContext, user?: any) {
  const hasUserContext = 'user' in appContext.ctx;
  if (hasUserContext === false) {
    Object.assign(appContext.ctx, { user: {} });
  }

  Object.assign(appContext.ctx['user'], new User(user));
}

function contextCookieHandler(appContext: AppContext) {
  ContextCookieHelper.flush(appContext);
}
