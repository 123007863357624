import DefaultSkin from './DefaultSkin';

type AlertSkinProps = {
  title: string;
  message: string;
  action: {
    onClick?: () => void;
    label?: string;
  };
  onClickClose?: () => void;
};
function AlertSkin({ title, message, action, onClickClose, ...props }: AlertSkinProps) {
  const actions = [
    {
      ...(action || {}),
      label: action?.label || '확인',
    },
  ];

  return (
    <DefaultSkin
      title={title}
      message={message}
      actions={actions}
      onClickClose={onClickClose}
      {...props}
    />
  );
}

export default AlertSkin;
