import { useIsomorphicLayoutEffect } from 'coreSrc/core/js/hooks';

import RichgoAnalytics from './RichgoAnalytics';

export default function useAnalytics() {
  useIsomorphicLayoutEffect(() => {
    const richgoAnalytics = new RichgoAnalytics();
    Object.assign(globalThis, { richgoAnalytics });
  }, []);
}
